import { County } from './county.model';
import { Account } from './account.model';

export class Person {
    id?: string;
    awsToken?: string;
    createdAt?: Date;
    updatedAt?: Date;
    firstName?: string;
    familyName?: string;
    middleName?: string;
    phone?: string;
    email?: string;
    aboutMe?: string;
    imageAvatarURL?: string;
    county?: County;
    dateOfBirth?: Date;
    systemRole?: string;
    completedSetup?: boolean;
    transactionAccount?: Account;

    constructor(
        id?: string,
        awsToken?: string,
        createdAt?: Date,
        updatedAt?: Date,
        firstName?: string,
        familyName?: string,
        middleName?: string,
        phone?: string,
        email?: string,
        aboutMe?: string,
        imageAvatarURL?: string,
        county?: County,
        dateOfBirth?: Date,
        systemRole?: string,
        completedSetup = false,
        transactionAccount?: Account,
    ) {
        this.id = id ? id : null;
        this.createdAt = createdAt ? createdAt : null;
        this.updatedAt = updatedAt ? updatedAt : null;
        this.awsToken = awsToken ? awsToken : null;
        this.firstName = firstName ? firstName : null;
        this.familyName = familyName ? familyName : null;
        this.middleName = middleName ? middleName : null;
        this.phone = phone ? phone : null;
        this.email = email ? email : null;
        this.aboutMe = aboutMe ? aboutMe : null;
        this.imageAvatarURL = imageAvatarURL ? imageAvatarURL : null;
        this.county = !!county ? county : new County();
        this.dateOfBirth = dateOfBirth ? dateOfBirth : null;
        this.systemRole = systemRole ? systemRole : null;
        this.completedSetup = completedSetup ? completedSetup : null;
        this.transactionAccount = transactionAccount ? transactionAccount : new Account();
    }
}

export interface PersonUpdate {
    awsToken: string;
    firstName: string;
    familyName: string;
    middleName: string;
    phone: string;
    email: string;
    aboutMe: string;
    imageAvatarURL: string;
    county: { connect: { id: string } };
    dateOfBirth: Date;
    systemRole: string;
    completedSetup: boolean;
}

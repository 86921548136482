import { Person } from './person.model';

export class Message {
    public id?: any;
    public text?: string;
    public author?: Person;
    public createdAt?: Date;
    public read?: boolean;

    constructor(
        id?: any,
        text?: any,
        author?: Person,
        read?: boolean
    ) {
        this.id = id ? id : null;
        this.text = text ? text : null;
        this.author = author ? author : null;
        this.read = read ? read : null;
    }
}

import { Notification } from '../../models/notification.model';

interface NotificationCreateInput {
    id: string;
    message: string;
    readDate: Date;
    directedPersons: { connect: { id: string }[] };
}

interface NotificationUpdateInput {
    message: string;
    readDate: Date;
    directedPersons: { connect: { id: string }[] };
}

export class NotificationMapperUtil {
     static NotificationToNotificationCreateInput(notification: Notification): NotificationCreateInput {
         return {
             id: notification.id,
             message: notification.message,
             readDate: notification.readDate,
             directedPersons: { connect: notification.directedPersons.map(person => {
                     return  { id: person.id };
                })
             }
         };
     }

     static NotificationToNotificationUpdateInput(notification: Notification): NotificationUpdateInput {
         return {
             message: notification.message,
             readDate: notification.readDate,
             directedPersons: { connect: notification.directedPersons.map(person => {
                 return  { id: person.id };
                })
             }
         };
     }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import swal from 'sweetalert2';
import { StateStorageService } from '../../shared/auth/state-storage.service';
import { User } from '../../models/user.model';
import { ChatViewService } from 'src/app/service/chatview.service';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html'
})
export class SignInComponent implements OnInit {
    private readonly sidebarVisible: boolean;
    authState: string;
    user: User = new User();
    password: string;
    newPassword: string;
    confirmPassword: string;
    requiredResponse = [];
    loading: boolean;
    errorMessage: string;
    forgotPassword: boolean;
    verificationCode: string;
    passwordsMatch: boolean;
    passwordLongEnough : boolean;

    constructor(private readonly router: Router, private readonly stateStorageService: StateStorageService, private chatViewService: ChatViewService) {
        this.sidebarVisible = false;
    }

    ngOnInit() {
        this.forgotPassword = false;
        this.passwordsMatch = false;
        this.passwordLongEnough = false;
        this.loading = false;
    }

    async SignIn() {
        this.trimInput();
        this.loading = true;
        if (this.forgotPassword) {
            this.forgottenPasswordSignIn();
        } else {
            this.authenticateUser();
        }
    }

    forgotPasswordClick() {
        if (!this.user.email || this.user.email.length === 0) {
            swal({
                title: 'You need to specify your email...',
                showConfirmButton: true,
                type: 'warning'
            }).catch();
        } else {
            this.forgotPassword = true;
            Auth.forgotPassword(this.user.email).then((response) => {
                swal({
                    title: 'A Verification Code has been sent.',
                    text: `${response.CodeDeliveryDetails.DeliveryMedium} sent to ${response.CodeDeliveryDetails.Destination}`,
                    showConfirmButton: true,
                    type: 'success'
                }).catch();
            }).catch(() => {
                swal({
                    title: 'Code not sent. Please try again after some time.',
                    showConfirmButton: true,
                    type: 'warning'
                }).catch();
            });
        }
    }

    async authenticateUser() {
        const user = await Auth.signIn(this.user.email, this.password).catch((error) => swal({
            title: 'Incorrect Username or password',
            timer: 2000,
            showConfirmButton: false,
            type: 'warning'
        }).catch());
        this.authState = user.challengeName;

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            const {requiredAttributes} = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
            this.requiredResponse = requiredAttributes;
            if (!!this.newPassword && this.newPassword.length > 0 && this.passwordsMatch) {
                const loggedUser = await Auth.completeNewPassword(user, this.newPassword,
                    // [user] = the Cognito User Object
                    // OPTIONAL, the required attributes (From the documentation.)
                {
                    'name': !!this.user.name ? this.user.name : user.challengeParam.userAttributes.name,
                    'family_name': !!this.user.familyName ? this.user.familyName : user.challengeParam.userAttributes.family_name,
                    'phone_number': !!this.user.phoneNumber ? this.user.phoneNumber : user.challengeParam.userAttributes.phone_number
                } // If not manually set from the user. Use the authentication attributes
                );
                this.setAuthTokenAndNavigate(loggedUser);
            }
            this.loading = false;
            } else {
                // The user directly signs in
                this.setAuthTokenAndNavigate(user);
            }
    }

    forgottenPasswordSignIn() {
        if (!!this.newPassword && this.newPassword.length > 0 && this.passwordsMatch) {
            Auth.forgotPasswordSubmit(this.user.email, this.verificationCode, this.newPassword)
                .then(() => {
                    Auth.signIn(this.user.email, this.newPassword).then((user) => {
                        this.setAuthTokenAndNavigate(user);
                    });
                }).catch((res) => {
                    console.log(res)
                swal({
                    title: 'Did not change Password.',
                    text: res.message,
                    showConfirmButton: true,
                    type: 'warning'
                }).catch();
                this.loading = false;
            });
        }
    }

    trimInput() {
        Object.keys(this.user).forEach(key => {
            if (!!this.user[key]) {
                this.user[key] = this.user[key].trim();
            }
        });
    }

    confirmPasswordsMatch() {
        this.passwordsMatch = (this.newPassword === this.confirmPassword);
        this.passwordLongEnough = (this.confirmPassword.length > 7)
    }

    passwordDidNotChangeError() {
        swal({
            title: 'Did not change Password.',
            text: 'Please make sure your passwords match and is at least 8 characters long',
            showConfirmButton: true,
            type: 'warning'
        }).catch();
    }

    setAuthTokenAndNavigate(user) {
        console.log("use===========>",user.signInUserSession)

        if (!!user.signInUserSession) {
            this.stateStorageService.setSessionToken(user.signInUserSession.accessToken.jwtToken);
            this.chatViewService.initialiazeUser();
        }
        this.router.navigate(['']).then(() => this.loading = false);
    }
}

import {Injectable} from '@angular/core';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from 'apollo-client';
import { Apollo, QueryRef } from 'apollo-angular';
import { FetchResult } from 'apollo-link';
import { Notification } from '../models/notification.model';
import { NotificationMapperUtil } from '../shared/mapper/notification-mapper-util';

@Injectable()
export class NotificationRepositoryService {

    constructor(private readonly apollo: Apollo) {}

    getAllNotifications(): Observable<FetchResult<any>> {
        return this.apollo.query({
            query: gql`
                query allNotifications {
                    notifications {
                        id
                        createdAt
                        message
                        readDate
                        directedPersons {
                            id
                            firstName
                            familyName
                        }
                    }
                }`,
            variables: {
                force: false
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }

    getNotificationsByAwsToken(personAwsToken: string): QueryRef<any> {
        return this.apollo.watchQuery({
            query: gql`
                query getNotificationByAwsID($awsToken: String!) {
                    notifications(where: {
                        AND: [{directedPersons_some: { awsToken: $awsToken }}, { readDate: null }]
                    }) {
                        id
                        createdAt
                        message
                        readDate
                        directedPersons {
                            id
                            firstName
                            familyName
                        }
                    }
                }`,
            variables: { awsToken: personAwsToken },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }

    createNotification(notification: Notification): Observable<FetchResult<Notification>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation createNotification($notification: NotificationCreateInput!) {
                    createNotification(data: $notification) {
                        id
                        createdAt
                        message
                        readDate
                        directedPersons {
                            id
                            firstName
                            familyName
                        }
                    }
                }`,
            variables: {
                notification: NotificationMapperUtil.NotificationToNotificationCreateInput(notification),
            },
            errorPolicy: 'all'
        });
    }

    updateNotification(notification: Notification): Observable<FetchResult<Notification>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation updateNotification($notification: NotificationUpdateInput!, $notificationId: ID!) {
                    updateNotification(data: $notification, where: {id: $notificationId}) {
                        id
                        createdAt
                        message
                        readDate
                        directedPersons {
                            id
                            firstName
                            familyName
                        }
                    }
                }`,
            variables: {
                notification: NotificationMapperUtil.NotificationToNotificationUpdateInput(notification),
                notificationId: notification.id
            },
            errorPolicy: 'all'
        });
    }

    getNotification(id: string): Observable<ApolloQueryResult<any>> {
        return this.apollo.query({
            query: gql`
                query getNotificationById($notificationId: ID!) {
                    notification(where: {id: $notificationId}) {
                        id
                        createdAt
                        message
                        readDate
                        directedPersons {
                            id
                            firstName
                            familyName
                        }
                    }
                }`,
            variables: { notificationId: id },
            errorPolicy: 'all'
        });
    }
}

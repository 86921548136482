import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { SignInComponent } from './layouts/sign-in/sign-in.component';
import { AppRoutes } from './app.routing';
import { AmplifyAngularModule, AmplifyModules, AmplifyService } from 'aws-amplify-angular';
import { Auth, Interactions } from 'aws-amplify';
import { UserRouteAccessService } from './shared/auth/user-route-access-service';
import { StateStorageService } from './shared/auth/state-storage.service';
import { CognitoService } from './service/cognito.service';
import { AppAlertModule } from './shared/alert/alert.module';
import { XlsxReaderModule } from './shared/xlsx-reader/xlsx-reader.module';
import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { environment } from '../environments/environment';
import { ChatModule } from './shared/chat-module/chat.module';
import { PersonRepositoryService } from './service/person.service';
import { split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { MaterialModule } from './shared/material-module';
import * as jwt from 'jsonwebtoken';
import { ChatViewService } from 'src/app/service/chatview.service';
import {BookingRepositoryService} from './service/booking.service';
import {TheoryBookingRepositoryService} from './service/theory-booking.service';
import {DrivingLessonsRepositoryService} from './service/driving-lessons.service';
import {PassPlusRepositoryService} from './service/pass-plus.service';
import {TestCentreRepositoryService} from './service/test-centre.service';
import {SwapBookingRepositoryService} from './service/swap-booking.service';

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        AppAlertModule,
        RouterModule.forRoot(AppRoutes, {
            useHash: true
        }),
        HttpClientModule,
        MaterialModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        AmplifyAngularModule,
        XlsxReaderModule,
        ApolloModule,
        HttpLinkModule,
        ChatModule
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        SignInComponent,
    ],
    providers: [
        TestCentreRepositoryService,
        PersonRepositoryService,
        UserRouteAccessService,
        StateStorageService,
        BookingRepositoryService,
        TheoryBookingRepositoryService,
        SwapBookingRepositoryService,
        DrivingLessonsRepositoryService,
        PassPlusRepositoryService,
        CognitoService,
        {
            provide: AmplifyService,
            useFactory: () => {
                return AmplifyModules({
                    Auth,
                    Interactions
                });
            }
        },
        ChatViewService,
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor(
        apollo: Apollo,
        httpLink: HttpLink
    ) {
        const http = httpLink.create({
            uri: environment.prismaService
        });

        // Create a WebSocket link:
        const ws = new WebSocketLink({
            uri: environment.webSocket,
            options: {
                reconnect: true,
                connectionParams: {
                    authorization: `Bearer ${jwt.sign(
                        {
                            data: {
                                service: 'dev@default',
                            },
                        },
                        '8Q2PhB7eLeIL3Waz4fyL0RlHPg4c41k77af3vsjUrb1q',
                        { algorithm: 'HS256' },
                    )}`,
                    // authorization: ''
                }
            }
        });

        // using the ability to split links, you can send data to each link
        // depending on what kind of operation is being sent
        const link = split(
            // split based on operation type
            ({ query }) => {
                const definition = getMainDefinition(query);
                return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
            },
            ws,
            http,
        );

        apollo.create({
            link,
            cache: new InMemoryCache()
        });
    }
}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Location } from '@angular/common';
import { NotificationRepositoryService } from '../../service/notification.service';
import { Auth } from 'aws-amplify';
import { User } from '../../models/user.model';
import { QueryRef } from 'apollo-angular';
import { Notification } from '../../models/notification.model';
import gql from 'graphql-tag';
import { PersonRepositoryService } from '../../service/person.service';
import { Person } from '../../models/person.model';
import { RouteInfo, SideBarRoutes } from '../../sidebar/sidebar.routes';
import { InputUtil } from '../utils/input-util';
import { CustomAlertTypeConst } from '../alert/custom-alert-type';

const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
};
declare var $: any;

@Component({
    selector: 'app-navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
    mobileMenuVisible: any = 0;
    private readonly nativeElement: Node;
    private toggleButton: any;
    private sidebarVisible: boolean;
    private _router: Subscription;

    @ViewChild('app-navbar-cmp', {static: false}) button: any;
    profileOpen: boolean;
    notificationOpen: boolean;
    cognitoUser: User = new User();
    currentUser: Person = new Person();
    notifications: Notification[] = [];
    notificationQuery: QueryRef<any>;
    searchText = '';
    searchTimeout = null;

    alert = 0;
    type = '';
    message = '';

    constructor(location: Location, private readonly element: ElementRef,
                private readonly router: Router,
                private readonly notificationService: NotificationRepositoryService,
                private readonly personService: PersonRepositoryService) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }

    ngOnInit() {
        this.initialiseNavbar();
        this.profileOpen = false;
        this.notificationOpen = false;

        Auth.currentUserInfo().then((user) => {
            const attributes = user.attributes;
            this.cognitoUser = new User(attributes.sub, '', attributes.name, attributes.family_name, '', attributes.email);

            this.notificationQuery = this.notificationService.getNotificationsByAwsToken(this.cognitoUser.id);
            this.notificationQuery.valueChanges
                .subscribe(result => {
                    this.notifications = result.data.notifications;
                });

            this.personService.getPersonByAwsId(this.cognitoUser.id).subscribe(result => {
                this.currentUser = result.data.person;
            });
            this.subscribeToNotifications(this.cognitoUser.id);
        });
    }

    subscribeToNotifications(personAwsId: string) {
        this.notificationQuery.subscribeToMore({
            document: gql`
                subscription notificationChanged($awsId: String!) {
                    notification(where: { node: {AND: [{directedPersons_some: { awsToken: $awsId } }, {readDate: null}] } }) {
                        node {
                            id
                            createdAt
                            message
                            readDate
                            directedPersons {
                                id
                                firstName
                                familyName
                            }
                        }
                    }
                }`,
            variables: {
                awsId: personAwsId,
            },
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) {
                    return prev;
                } else {
                    const newNotification = subscriptionData.data.notification.node;
                    if (newNotification['readDate'] === null) {
                        if (newNotification.message.text.includes('You have a new Message')) {
                            this.notify(newNotification.message.text, CustomAlertTypeConst.INFO);
                        }
                        this.notifications.push(newNotification);
                    }
                }
            }
        });
    }

    notify(errorMessage: string, type: string) {
        this.type = type;
        this.message = errorMessage;
        this.alert++;
    }

    initialiseNavbar() {
        this.listTitles = SideBarRoutes;

        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        if (body.classList.contains('hide-sidebar')) {
            misc.hide_sidebar_active = true;
        }
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
            this.sidebarClose();

            const $layer = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
            }
        });
    }

    onResize(event) {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }

    minimizeSidebar() {
        const body = document.getElementsByTagName('body')[0];

        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;

        } else {
            setTimeout(function() {
                body.classList.add('sidebar-mini');

                misc.sidebar_mini_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function() {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function() {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    hideSidebar() {
        const body = document.getElementsByTagName('body')[0];
        const sidebar = document.getElementsByClassName('sidebar')[0];

        if (misc.hide_sidebar_active === true) {
            setTimeout(function() {
                body.classList.remove('hide-sidebar');
                misc.hide_sidebar_active = false;
            }, 300);
            setTimeout(function() {
                sidebar.classList.remove('animation');
            }, 600);
            sidebar.classList.add('animation');

        } else {
            setTimeout(function() {
                body.classList.add('hide-sidebar');
                misc.hide_sidebar_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function() {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function() {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    sidebarOpen() {
        const $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function() {
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');
        setTimeout(function() {
            $toggle.classList.add('toggled');
        }, 430);

        const $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');

        if (body.querySelectorAll('.main-panel')) {
            document.getElementsByClassName('main-panel')[0].appendChild($layer);
        } else if (body.classList.contains('off-canvas-sidebar')) {
            document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
        }

        setTimeout(function() {
            $layer.classList.add('visible');
        }, 100);

        $layer.onclick = function() { // asign a function
            body.classList.remove('nav-open');
            this.mobileMenuVisible = 0;
            this.sidebarVisible = false;

            $layer.classList.remove('visible');
            setTimeout(function() {
                $layer.remove();
                $toggle.classList.remove('toggled');
            }, 400);
        }.bind(this);

        body.classList.add('nav-open');
        this.mobileMenuVisible = 1;
        this.sidebarVisible = true;
    }

    sidebarClose() {
        const $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        const $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');

        this.sidebarVisible = false;
        body.classList.remove('nav-open');
        body.classList.remove('nav-open');
        if ($layer) {
            $layer.remove();
        }

        setTimeout(function() {
            $toggle.classList.remove('toggled');
        }, 400);

        this.mobileMenuVisible = 0;
    }

    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    getTitle() {
        let titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
        }
        return InputUtil.kebabCaseToNormalCase(InputUtil.slashToNormalCase(titlee)).toUpperCase().split(' ')
            .filter(word => word.length <= 24).join(' ');
    }

    toggleProfile() {
        this.profileOpen = !this.profileOpen;
    }

    profileClose(event) {
        this.profileOpen = event;
    }

    toggleNotification() {
        this.notificationOpen = !this.notificationOpen;
    }

    notificationClose(event) {
        this.notificationOpen = event;
    }

    onNotificationUpdate(notifications) {
        this.notifications = notifications;
    }

    onSearch() {
        if (!!this.searchText) {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                let relevantRoutePath = '';
                SideBarRoutes.forEach(route => relevantRoutePath = this.setPathIfFound(route, relevantRoutePath));
                if (!!relevantRoutePath) {
                    this.router.navigate([relevantRoutePath]).then();
                }
            }, 700);
        }
    }

    setPathIfFound(route: RouteInfo, pathToKeep: string): string {
        if (!!route.children && route.children.length > 0) {
            route.children.forEach(childRoute => {
                if (childRoute.title.toLowerCase().includes(this.searchText.toLowerCase())) {
                    pathToKeep = `${route.path}/${childRoute.path}`;
                    return pathToKeep;
                }
            });
        } else {
            if (route.title.toLowerCase().includes(this.searchText.toLowerCase())) {
                return route.path;
            }
        }
        return pathToKeep;
    }
}

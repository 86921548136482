export class User {
    public id?: any;
    public username?: string;
    public name?: string;
    public familyName?: string;
    public preferredName?: string;
    public email?: string;
    public phoneNumber?: string;
    middleName: string;

    constructor(
        id?: any,
        username?: string,
        name?: string,
        familyName?: string,
        preferredName?: string,
        email?: string,
        phoneNumber?: string
    ) {
        this.id = id ? id : null;
        this.username = username ? username : null;
        this.name = name ? name : null;
        this.familyName = familyName ? familyName : null;
        this.preferredName = preferredName ? preferredName : null;
        this.email = email ? email : null;
        this.phoneNumber = phoneNumber ? phoneNumber : null;
    }

}

export const environment = {
  production: false,
  region: 'eu-west-2',
  UserPoolId: 'eu-west-2_DArnC3i1i',
  // prismaService: 'https://8ic459fny5.execute-api.eu-west-1.amazonaws.com/uat/',
//  prismaService: 'http://localhost:3000/dev/',
   prismaService: 'https://8x35wmx0a4.execute-api.eu-west-1.amazonaws.com/dev-new/',
  // prismaService: 'https://ico0l6zrt3.execute-api.eu-west-1.amazonaws.com/dev-new/',
 

  // prismaService: 'http://10.10.0.69:8000/api/test-bookings',
  webSocket: `wss://lidt-publi-ub924tdw2ha8-1092836819.eu-west-1.elb.amazonaws.com/uat`,
  // webSocket: 'ws://localhost:4467/dev',
  tempPass: 'tempP@1234',
  botEndPoint: 'https://a12f-2405-201-4014-a0a5-9cfb-64bb-5f3f-e839.ngrok-free.app'
};
 

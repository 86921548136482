import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Observable, } from 'rxjs';
import { ApolloQueryResult } from 'apollo-client';
import { Apollo } from 'apollo-angular';
import { FetchResult } from 'apollo-link';
import { Booking } from '../models/booking.model';
import { BookingMapperUtil } from '../shared/mapper/booking-mapper-util';

export const bookingFields = `
    id
    createdAt
    updatedAt
    learner {
        id
        person {
            id
            firstName
            middleName
            familyName
            phone 
            email
        }
        licence {
            id
            number
        }
        instructor{
            id
            person {
                id
                firstName
                familyName
                email
                phone
            }
        }

    }
    swapHistory {
        swapDate
        booking {
            id
            learner {
                id
                person {
                    id
                    firstName
                    familyName
                    middleName
                }
            }
        }
        swapWith {
            id
            learner {
                id
                person {
                    id
                    firstName
                    familyName
                    middleName
                }
                licence {
                    id
                    number   
                }
            }
        }
    }
    bookingFee
    provincialLicenseNumber
    bookingProfile
    bookingDate
    bookingReference
    bookedLearner
    confirmedDate
    bookingPaid
    metaDate
    metaLocation
    note
    lockedWith
    startDateWeeks
    swapToLearner{
        id
        paymentActive
        person {
            id
            firstName
            familyName
            middleName
            email
            phone
        }
        instructor{
            id
            person {
                id
                firstName
                familyName
                email
                phone
            }
        }
    }
    testCentre {
        id
        createdAt
        updatedAt
        name
        addressLine1
        addressLine2
        postCode
        type
        city {
            id
            createdAt
            updatedAt
            name
            county {
                id
                createdAt
                updatedAt
                name
                description
            }
        }
        instructorRate
    }
    cancellationDate
    type
    stockType
    passResult
    eligibleDate
    mailSent
    botStatus
    botBookingDate
    bot {
        botName
    }
    bookingColor
`;

@Injectable()
export class BookingRepositoryService {

    constructor(private readonly apollo: Apollo) { }
    getAllBookings(pageLimit?: number, searchQuery?: string, skip = 0): Observable<FetchResult<any>> {
        return this.apollo.query({
            query: gql`
                query allBookings($first: Int, $skip: Int ${!!searchQuery ? ', $searchQuery: String' : ''}) {
                    bookings(first: $first, skip: $skip ${!!searchQuery ? ', where: {name_contains: $searchQuery}' : ''}) {
                        ${bookingFields}
                    }
                }`,
            variables: {
                searchQuery,
                skip,
                first: !!pageLimit ? pageLimit : 100000,
                force: false
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }
    getAllBookingsByType(type: string, stockType: string, pageLimit?: number, searchQuery?: string, skip = 0, sortBy?: string): Observable<FetchResult<any>> {
        
        
        let firstNameSearch = null;
        let middleNameSearch = null;
        let familyNameSearch = null;
        
        if (searchQuery) {
            console.log("searchQuery.split--->",);
            if (searchQuery.split(" ").length === 1) {
                firstNameSearch = searchQuery.split(" ")[0];
                middleNameSearch = searchQuery.split(" ")[0];
                familyNameSearch = searchQuery.split(" ")[0];
            } else if (searchQuery.split(" ").length === 2) {
                firstNameSearch = searchQuery.split(" ")[0];
                familyNameSearch = searchQuery.split(" ")[1].length > 1 ? searchQuery.split(" ")[1] : null;
            } else {
                firstNameSearch = searchQuery.slice(0, -2);
                familyNameSearch = searchQuery.slice(-2);
                middleNameSearch = searchQuery.slice(2, -2);
            }
        }

        return this.apollo.query({
            query: gql`
                query allBookingsByType($type: BookingType, $stockType: BookingStock, $first: Int, $skip: Int ${!!searchQuery ? ', $firstNameSearch: String, $middleNameSearch: String, $familyNameSearch: String' : ''}) {
                    bookings(first: $first, skip: $skip,
                    where: {
                        AND: [
                            { type: $type },
                            ${stockType ? '{ stockType: $stockType }' : ''}
                            ${!!searchQuery ? '{ OR: [{ learner: { person: { firstName_contains: $firstNameSearch } } }, { learner: { person: { middleName_contains: $middleNameSearch } } }, { learner: { person: { familyName_contains: $familyNameSearch } } }] }' : ''}
                        ]
                    },
                    orderBy: bookingDate_DESC
                    
                    ) {
                        ${bookingFields}
                    }
                }`,
            variables: {
                type,
                stockType,
                firstNameSearch,
                middleNameSearch,
                familyNameSearch,
                skip,
                first: !!pageLimit ? pageLimit : 100000,
                force: false
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }
    getAllSwapBookings(type: string, stockType: string, pageLimit?: number, searchQuery?: string, skip = 0): Observable<FetchResult<any>> {
        
        
        let firstNameSearch = null;
        let middleNameSearch = null;
        let familyNameSearch = null;
        
        if (searchQuery) {
            if (searchQuery.split(" ").length === 1) {
                firstNameSearch = searchQuery.split(" ")[0];
                middleNameSearch = searchQuery.split(" ")[0];
                familyNameSearch = searchQuery.split(" ")[0];
            } else if (searchQuery.split(" ").length === 2) {
                firstNameSearch = searchQuery.split(" ")[0];
                familyNameSearch = searchQuery.split(" ")[1].length > 1 ? searchQuery.split(" ")[1] : null;
            } else {
                firstNameSearch = searchQuery.slice(0, -2);
                familyNameSearch = searchQuery.slice(-2);
                middleNameSearch = searchQuery.slice(2, -2);
            }
        }

        return this.apollo.query({
            query: gql`
                query allBookingsByType($type: BookingType, $stockType: BookingStock, $first: Int, $skip: Int ${!!searchQuery ? ', $firstNameSearch: String, $middleNameSearch: String, $familyNameSearch: String' : ''}) {
                    getSwapBookings(first: $first, skip: $skip,
                    where: {
                        AND: [
                            { type: $type },
                            ${stockType ? '{ stockType: $stockType }' : ''}
                            ${!!searchQuery ? '{ OR: [{ learner: { person: { firstName_contains: $firstNameSearch } } }, { learner: { person: { middleName_contains: $middleNameSearch } } }, { learner: { person: { familyName_contains: $familyNameSearch } } }] }' : ''}
                        ]
                    },
                    orderBy: bookingDate_DESC
                    ) {
                        id
                        bookingDate
                        type
                        testCentre {
                            id
                            name
                            subscriptionId
                        }
                        mailSent
                        bookingReference
                        bookingProfile
                        cancellationDate
                        provincialLicenseNumber
                        bookingFee
                        metaLocation
                        metaDate
                        bookingColor
                        learner {
                            id
                            createdAt
                            availableHours
                            person {
                              id
                              firstName
                              middleName
                              familyName
                              email
                            }
                            firebaseToken
                            instructor {
                                id
                                person {
                                    id
                                    email
                                }
                            }
                        }
                        swapHistory {
                            swapDate
                            swapWith {
                                id
                                learner {
                                    id
                                    person {
                                        id
                                        firstName
                                        familyName
                                        middleName
                                    }
                                    licence {
                                        id
                                        number   
                                    }
                                }
                            }
                            booking {
                                id
                                learner {
                                    id
                                    person {
                                        id
                                        firstName
                                        familyName
                                        middleName
                                    }
                                }
                            }
                        }
                    }
                }`,
            variables: {
                type,
                stockType,
                firstNameSearch,
                middleNameSearch,
                familyNameSearch,
                skip,
                first: !!pageLimit ? pageLimit : 100000,
                force: false
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
        
    }
    createBooking(booking: Booking): Observable<FetchResult<Booking>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation createBooking($booking: BookingCreateInput!) {
                    createBooking(data: $booking) {
                        ${bookingFields}
                    }
                }`,
            variables: {
                booking: BookingMapperUtil.BookingToBookingCreateInput(booking),
            },
            errorPolicy: 'all'
        });
    }

    updateBooking(booking: Booking): Observable<FetchResult<Booking>> {
        console.log('bookings ===>>> ', booking)
        return this.apollo.mutate({
            mutation: gql`
                mutation updateBooking($booking: BookingUpdateInput!, $bookingId: ID!) {
                    updateBooking(data: $booking, where: {id: $bookingId}) {
                        ${bookingFields}
                    }
                }`,
            variables: {
                booking: BookingMapperUtil.BookingToBookingUpdateInput(booking),
                bookingId: booking.id
            },
            errorPolicy: 'all'
        });
    }

    getBooking(id: string): Observable<ApolloQueryResult<any>> {
        return this.apollo.query({
            query: gql`
                query getBookingById($bookingId: ID!) {
                    booking(where: {id: $bookingId}) {
                        ${bookingFields}
                    }
                }`,
            variables: { bookingId: id },
            errorPolicy: 'all'
        });
    }

    deleteBooking(id: string): Observable<FetchResult<Booking>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation deleteBookingById($bookingId: ID!) {
                    deleteBooking(where: {id: $bookingId}) {
                        id
                    }
                }`,
            variables: { bookingId: id },
            errorPolicy: 'all'
        });
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ChatModalComponent } from './chat-modal/chat-modal.component';
import { ChatRepositoryService } from 'src/app/service/chat.service';
import { FormsModule } from '@angular/forms';
import { MatTabsModule, MatListModule, MatIconModule, MatRippleModule, MatProgressBarModule } from '@angular/material';
import { ChatUserListComponent } from './chat-modal/chat-user-list/chat-user-list.component';
import { PersonRepositoryService } from 'src/app/service/person.service';
import { AppAlertModule } from '../alert/alert.module';
import { ChatViewComponent } from './chat-modal/chat-view/chat-view.component';
import { MaterialModule } from '../../shared/material-module';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        MatTabsModule,
        MatListModule,
        MatIconModule,
        MatRippleModule,
        MatProgressBarModule,
        AppAlertModule,
        MaterialModule,
    ],
    declarations: [
        ChatModalComponent,
        ChatUserListComponent,
        ChatViewComponent],
    providers: [
        ChatRepositoryService,
        PersonRepositoryService
    ],
    exports: [ChatModalComponent]
})

export class ChatModule { }

import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from 'apollo-client';
import { FetchResult } from 'apollo-link';
import { Conversation } from '../models/conversation.model';
import { ConversationMapperUtil } from '../shared/mapper/conversation-mapper-util';

@Injectable()
export class ChatRepositoryService {

    constructor(private apollo: Apollo) { }

    createConversation(conversation: Conversation): Observable<FetchResult<Conversation>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation createConversation($conversation: ConversationCreateInput!) {
                    createConversation(data: $conversation) {
                        id
                        name
                        type
                        author {
                            id
                            firstName
                        }
                        members {
                            id
                            firstName
                            systemRole
                        }
                    }
                }`,
            variables: {
                conversation: ConversationMapperUtil.ConversationToConversationCreateInput(conversation),
            },
            errorPolicy: 'all'
        });
    }

    updateConversation(conversation: Conversation): Observable<FetchResult<Conversation>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation updateConversation($conversation: ConversationUpdateInput!, $conversationId: ID!) {
                    updateConversation(data: $conversation, where: {id: $conversationId}) {
                        id
                        name
                        type
                        messages {
                            id
                            text
                            read
                            author {
                                id
                                firstName
                            }
                            createdAt
                        }
                        members {
                            id
                            firstName
                            systemRole
                        }
                        updatedAt
                        createdAt
                    }
                }`,
            variables: {
                conversation: ConversationMapperUtil.ConversationToConversationUpdateInput(conversation),
                conversationId: conversation.id
            },
            errorPolicy: 'all'
        });
    }

    markConversationMessagesAsRead(conversationId: String, msgIds: String[]): Observable<FetchResult<Conversation>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation UpdateConversation($conversationId: ID!, $msgIds: [ID!]) {
                    updateConversation(
                        where: { id: $conversationId }
                        data: {
                            messages: {
                                updateMany: {
                                    data: { read: true },
                                    where: { id_in: $msgIds }
                                }
                            }
                        },
                    ) {
                        id
                        name
                        type
                        messages {
                            id
                            text
                            read
                            author {
                                id
                                firstName
                            }
                            createdAt
                        }
                        members {
                            id
                            firstName
                            systemRole
                        }
                        updatedAt
                        createdAt
                    }
                }`,
            variables: {
                conversationId,
                msgIds,
            },
            errorPolicy: 'all'
        });
    }

    getConversationById(id: string): Observable<ApolloQueryResult<any>> {
        return this.apollo.watchQuery({
            query: gql`
                query getConversationById($conversationId: ID!) {
                    conversation(where: {id: $conversationId}) {
                        id
                        name
                        type
                        messages {
                            id
                            text
                            read
                            author {
                                id
                                firstName
                                familyName
                            }
                            createdAt
                        }
                        members {
                            id
                            firstName
                            systemRole
                        }
                    }
                }`,
            variables: { conversationId: id },
            errorPolicy: 'all'
        }).valueChanges;
    }

    conversationsUpdated(userId: string): Observable<any> {
        return this.apollo.subscribe({
            query: gql`
        subscription conversationUpdated($userId: ID!) {
            conversation(
                where: {
                    node: {
                        members_some: { id: $userId }
                    }
                }   
            ) {
                node {
                        id
                        name
                        type
                        messages {
                            id
                            text
                            read
                            author {
                                id
                                firstName
                                familyName
                            }
                            createdAt
                        }
                        members {
                            id
                            firstName
                            systemRole
                        }
                    }
                }
    }`,
            variables: { userId },
        });
    }

    getConversationByMemberId(memberId: string): Observable<ApolloQueryResult<any>> {
        return this.apollo.watchQuery({
            query: gql`
                query getConversationsByMembers($memberId: ID!) {
                    conversations(where: { members_some: { id: $memberId } }) {
                        id
                        name
                        type
                        messages {
                            id
                            text
                            read
                            author {
                                id
                                firstName
                                familyName
                            }
                            createdAt
                        }
                        members {
                            id
                            firstName
                            familyName
                            systemRole
                        }
                        createdAt
                        updatedAt
                    }
                }`,
            variables: { memberId: memberId },
            errorPolicy: 'all'
        }).valueChanges;
    }

    getUserConversation(authorId: string, recipientId: string, conversationType: string): Observable<ApolloQueryResult<any>> {
        return this.apollo.watchQuery({
            query: gql`
                query getUserConversations($authorId: ID, $recipientId: ID, $conversationType: ConversationType) {
                    userConversation(authorId: $authorId, recipientId: $recipientId, conversationType: $conversationType) {
                        id
                        name
                        type
                        messages {
                            id
                            text
                            read
                            author {
                                id
                                firstName
                                familyName
                            }
                            createdAt
                        }
                        members {
                            id
                            firstName
                            familyName
                            systemRole
                        }
                        createdAt
                        updatedAt
                    }
                }`,
            variables: {
                authorId: authorId,
                recipientId: recipientId,
                conversationType: conversationType
            },
            errorPolicy: 'all'
        }).valueChanges;
    }

}

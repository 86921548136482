import { Component, OnInit, Input, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { Conversation } from 'src/app/models/conversation.model';
import { ChatViewService } from 'src/app/service/chatview.service';

@Component({
    selector: 'app-chat-user-list',
    templateUrl: 'chat-user-list.component.html',
    styleUrls: ['chat-user-list.component.css'],
})

export class ChatUserListComponent implements OnInit, OnChanges {

    @Input() conversations: Conversation[];
    conversation: Conversation;

    constructor(protected chatViewService: ChatViewService) { }

    ngOnInit(): void {

    }

    ngOnChanges() {
        this.ngOnInit();
    }

    getColor(converstation) {
        const messages = this.chatViewService.getUnreadMessages(converstation);
        return messages.length > 0 ? '#26c6da' : '#43a047';
    }

    onSelectConversation($data) {
        this.conversation = $data;
        this.chatViewService.markMessagesAsRead($data);
    }

    @Input()
    set getConversationInput(event) {
        this.conversations = event;
        if (!!this.conversation) {
            this.conversation = this.conversations.find(con => con.id === this.conversation.id);
        }
    }

    getCreatedDate($date) {
        return moment($date).fromNow();
    }

    getUsername(members: any[]) {
        return members.find(x => x.id !== this.chatViewService.currentUser.id);
    }

}

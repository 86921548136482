import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { Auth } from 'aws-amplify';
import { User } from '../models/user.model';
import { CognitoService } from '../service/cognito.service';
import { PersonRepositoryService } from '../service/person.service';
import { Person } from '../models/person.model';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { RouteInfo, SideBarRoutes } from './sidebar.routes';

declare const $: any;


@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: RouteInfo[];
    ps: any;
    cognitoUser: User = new User();
    currentUser: Person = new Person();

    constructor(private readonly cognitoService: CognitoService, private readonly personService: PersonRepositoryService,
                private readonly router: Router) {
    }

    ngOnInit() {
        this.loadCurrentUser();
        this.menuItems = SideBarRoutes.filter(menuItem => menuItem);
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar as HTMLElement);
        }
    }

    isMini() {
        return document.querySelector('.sidebar-wrapper').clientWidth <= 160;
    }

    signOut() {
        swal({
            title: 'Are you sure you want to sign out?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No, take me back',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then((reason) => {
            // if cancelled @reason is { dismiss:'cancelled' }
            // else @reason is {value: true}
            if (reason.value) {
                Auth.signOut().then(() => {
                    this.router.navigate(['login']).then();
                }).catch(() => alert('There was an issue signing out of your account'));
            }
        }).catch();
    }

    loadCurrentUser() {
        Auth.currentUserInfo().then((user) => {
            const attributes = user.attributes;
            this.cognitoUser = new User(attributes.sub, '', attributes.name, attributes.family_name, '', attributes.email);

            this.personService.getPersonByAwsId(this.cognitoUser.id).subscribe(result => {
                this.currentUser = result.data.person;
            });
        });
    }

    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
}

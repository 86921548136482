import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { StateStorageService } from './state-storage.service';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { PersonRepositoryService } from '../../service/person.service';

@Injectable()
export class UserRouteAccessService implements CanActivate {

    constructor(private readonly router: Router, private readonly stateStorageService: StateStorageService,
                private readonly personService: PersonRepositoryService) {}

    canActivate(): Promise<boolean> {
        // Force user to login if no session.
        if (!this.checkSession()) {
            this.router.navigate(['login']).then();
            return Promise.resolve(false);
        } else {
            return this.checkLoggedIn();
        }
    }

    checkLoggedIn(): Promise<boolean> {
        // First checks the authentication and then sets the token.
        // If the token was set the check session should return true.
        return Promise.resolve(this.setAuthTokenFromAWS().then(() => {
            const isValidSession = this.checkSession();
            if (!isValidSession) {
                this.router.navigate(['login']).then();
                return false;
            }
            return isValidSession;
        }).catch(() => false));
    }

    checkSession() {
        return !!this.stateStorageService.getSessionToken() && this.stateStorageService.getSessionToken().length > 0;
    }

    setAuthTokenFromAWS(): Promise<CognitoUserSession> {
        return new Promise((resolve, reject) => {
            Auth.currentSession().then((auth) => {
                if (auth.isValid()) {
                    this.personService.getPersonByAwsIdAndIsAdmin(auth.getIdToken().payload.sub).subscribe(res => {
                        if (!!res.errors && res.errors.length > 0) {
                            // Issue with the person object
                            this.stateStorageService.removeSessionToken();
                            reject(null);
                        } else {
                            if (!!res.data && res.data.persons.length === 1 &&
                                res.data.persons[0].awsToken === auth.getIdToken().payload.sub) {
                                // Signed in
                                this.stateStorageService.setSessionToken(auth.getAccessToken().getJwtToken());
                                resolve(auth);
                            } else {
                                // Not an admin.
                                reject(null);
                                this.stateStorageService.removeSessionToken();
                            }
                        }
                    });
                } else {
                    reject(null);
                    this.stateStorageService.removeSessionToken();
                }
            }).catch(() => {
                // Failed on cogito
                reject(null);
                this.stateStorageService.removeSessionToken();
            });
        });
    }
}

// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:3da282a6-991a-48b9-9b8e-93e216461e34",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_DArnC3i1i",
    "aws_user_pools_web_client_id": "2sq7gv79r8ef85lqdrvs90cnvo",
    "oauth": {}
};


export default awsmobile;

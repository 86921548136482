import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Observable, } from 'rxjs';
import { ApolloQueryResult } from 'apollo-client';
import { Apollo } from 'apollo-angular';
import { FetchResult } from 'apollo-link';
import { Booking } from '../models/booking.model';
import { TheoryBookingMapperUtil } from '../shared/mapper/theory-booking-mapper-util';
import { ActiveTabTypes } from '../shared/filter/test-booking-filter.component';
import { TheoryBooking } from '../models/theory-booking.model';

export const theoryBookingFields = `
    id
    createdAt
    updatedAt
    theoryTestDate
    theoryTestCentre
    theoryTestPrice
    theoryTestStatus
    reservedUntil
    reservedId
    theoryTestAdmin
    theoryTestVat
    theoryTestTransactionFee
    note
    learner {
        id
        person {
            id
            firstName
            middleName
            familyName
            phone 
            email
        }
        licence {
            id
            number
        }
        instructor{
            id
            person {
                id
                firstName
                familyName
                email
                phone
            }
        }

    }
`;

@Injectable()
export class TheoryBookingRepositoryService {

    constructor(private readonly apollo: Apollo) { }
    getAllTheoryBookings(pageLimit?: number, searchQuery?: string, skip = 0): Observable<FetchResult<any>> {
        return this.apollo.query({
            query: gql`
                query allTheoryBookings($first: Int, $skip: Int ${!!searchQuery ? ', $searchQuery: String' : ''}) {
                    theoryBookings(first: $first, skip: $skip ${!!searchQuery ? ', where: {name_contains: $searchQuery}' : ''}) {
                        ${theoryBookingFields}
                    }
                }`,
            variables: {
                searchQuery,
                skip,
                first: !!pageLimit ? pageLimit : 100000,
                force: false
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }

    getAllTheoryBookingsByType(type: string, theoryTestStatus: string, pageLimit?: number, searchQuery?: string, skip = 0): Observable<FetchResult<any>> {
        return this.apollo.query({
            query: gql`
                query allTheoryBookingsByType(
                    $theoryTestStatus: TheoryStatus
                    $first: Int, 
                    $skip: Int 
                    ${!!searchQuery ? ', $searchQuery: String' : ''}) {
                    theoryBookings(first: $first, skip: $skip,
                    where: {
                        AND: [
                            {theoryTestStatus: $theoryTestStatus},
                            ${!!searchQuery ? ', {learner: {person: {firstName_contains: $searchQuery}}}' : ''}
                        ]
                    }) {
                        ${theoryBookingFields}
                    }
                }`,
            variables: {
                theoryTestStatus,
                searchQuery,
                skip,
                first: !!pageLimit ? pageLimit : 100000,
                force: false
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }

    createTheoryBooking(theoryBooking: TheoryBooking): Observable<FetchResult<TheoryBooking>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation createTheoryBooking($theoryBooking: TheoryBookingCreateInput!) {
                    createTheoryBooking(data: $theoryBooking) {
                        ${theoryBookingFields}
                    }
                }`,
            variables: {
                theoryBooking: TheoryBookingMapperUtil.BookingToBookingCreateInput(theoryBooking),
            },
            errorPolicy: 'all'
        });
    }

    updateTheoryBooking(theoryBooking: TheoryBooking): Observable<FetchResult<TheoryBooking>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation updateTheoryBooking($theoryBooking: TheoryBookingUpdateInput!, $theoryBookingId: ID!) {
                    updateTheoryBooking(data: $theoryBooking, where: {id: $theoryBookingId}) {
                        ${theoryBookingFields}
                    }
                }`,
            variables: {
                theoryBooking: TheoryBookingMapperUtil.BookingToBookingUpdateInput(theoryBooking),
                theoryBookingId: theoryBooking.id
            },
            errorPolicy: 'all'
        });
    }

    getTheoryBooking(id: string): Observable<ApolloQueryResult<any>> {
        return this.apollo.query({
            query: gql`
                query getTheoryBookingById($bookingId: ID!) {
                    theoryBookings(where: {id: $bookingId}) {
                        ${theoryBookingFields}
                    }
                }`,
            variables: { bookingId: id },
            errorPolicy: 'all'
        });
    }

    deleteTheoryBooking(id: string): Observable<FetchResult<TheoryBooking>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation deleteTheoryBookingById($bookingId: ID!) {
                    deleteTheoryBooking(where: {id: $bookingId}) {
                        id
                    }
                }`,
            variables: { bookingId: id },
            errorPolicy: 'all'
        });
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular/dist/src/providers/amplify.service';
import { Auth } from 'aws-amplify';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CognitoService } from '../service/cognito.service';
import { User } from '../models/user.model';
import { Person } from '../models/person.model';
import { PersonRepositoryService } from '../service/person.service';

@Component({
    selector: 'app-user-sidebar',
    templateUrl: 'user-sidebar.component.html',
    animations: [
        trigger('openClose', [
            state('open', style({
                transform: 'translateX(-400px)',
            })),
            state('closed', style({
                transform: 'translateX(0px)',
            })),
            transition('open => closed', [
                animate('0.5s ease-in'),
            ]),
            transition('closed => open', [
                animate('0.5s ease-out')
            ]),
        ]),
    ],
})

export class UserSidebarComponent implements OnInit {
    cognitoUser: User = new User();
    currentUser: Person = new Person();
    @Input() isOpen: boolean;
    @Output() isClosed = new EventEmitter();
    badgeColors = [
        'badge-dark',
        'badge-success',
        'badge-info',
        'badge-danger',
        'badge-rose',
        'bg-transparent',
    ];
    badgeColor = '';
    iterator = 0;

    constructor(private readonly amplifyService: AmplifyService, readonly router: Router,
                private readonly cognitoService: CognitoService, private readonly personService: PersonRepositoryService) {}

    ngOnInit(): void {
        this.loadCurrentUser();
    }

    loadCurrentUser() {
        Auth.currentUserInfo().then((user) => {
            const attributes = user.attributes;
            this.cognitoUser = new User(attributes.sub, '', attributes.name, attributes.family_name, '', attributes.email);

            this.personService.getPersonByAwsId(this.cognitoUser.id).subscribe(result => {
                this.currentUser = result.data.person;
            });
        });
        this.badgeColor = this.badgeColors[this.iterator];
    }

    toggle() {
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
            this.loadCurrentUser();
        } else {
            this.close();
        }
    }

    signOut() {
        swal({
            title: 'Are you sure you want to sign out?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No, take me back',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then((reason) => {
            // if cancelled @reason is { dismiss:'cancelled' }
            // else @reason is {value: true}
            if (reason.value) {
                Auth.signOut().then(() => {
                    this.router.navigate(['login']).then();
                }).catch(() => alert('There was an issue signing out of your account'));
            }
        }).catch();
    }

    outOfFocusClick() {
        this.isOpen = false;
        this.close();
    }

    close() {
        this.isClosed.emit(false);
    }

    saveNewPassword() {
        this.cognitoService.updateCognitoUserPassword(this.cognitoUser, this.cognitoUser['password'], true).then(() => {
            swal(
                {
                    title: 'Success!',
                    text: 'Password has been changed.',
                    type: 'success',
                    timer: 2000,
                    confirmButtonClass: 'btn btn-success',
                    buttonsStyling: false
                }
            ).then();
            this.cognitoUser['password'] = null;
        }).catch(() => {
            swal(
                {
                    title: 'Something went wrong!',
                    text: 'Password has not been changed.',
                    type: 'error',
                    timer: 2000,
                    confirmButtonClass: 'btn btn-success',
                    buttonsStyling: false
                }
            ).then();
        });
    }

    changeBadgeColor() {
        this.iterator++;
        if (this.iterator >= this.badgeColors.length) {
            this.iterator = 0;
        }
        this.badgeColor = this.badgeColors[this.iterator];
    }
}

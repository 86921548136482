export const SystemRole = {
    ADMIN: 'Admin',
    INSTRUCTOR: 'Instructor',
    LEARNER: 'Learner',
};

export const BookingType = {
    PENDING: 'pending',
    FOR_SALE: 'for-sale',
    TO_SWAP: 'to-swap',
    SOLD: 'sold',
    WAITING:'waiting',
    ARCHIVE:'archive',
    DRIVING_LESSONS:'driving-lessons',
    PASS_PLUS:'pass-plus'
}

 

export const BookingStockType = {
    INTENSIVE: 'intensive-stock',
    FAST_TRACK: 'fast-track-stock',
};

export const InstructorStatus = {
    AWAITING_APPROVAL: 'AWAITING_APPROVAL',
    REJECTED: 'REJECTED',
    APPROVED: 'APPROVED',
    ARCHIVED: 'ARCHIVED',
};

export const ConversationType = {
    SUPPORT: 'SUPPORT',
    ADMIN_INSTRUCTOR: 'ADMIN_INSTRUCTOR',
    ADMIN_LEARNER: 'ADMIN_LEARNER',
    INSTRUCTOR_LEARNER: 'INSTRUCTOR_LEARNER',
};

export const TransactionStatus = {
    PENDING: 'PENDING',
    COMPLETED: 'COMPLETED',
    DECLINED: 'DECLINED',
    DRAFT:'DRAFT'
};

export const TransactionType = {
    INSTRUCTOR_PAYOUT: 'INSTRUCTOR_PAYOUT',
    INSTRUCTOR_BONUS: 'INSTRUCTOR_BONUS',
    TEST_PAYMENT: 'TEST_PAYMENT',
    PACKAGE_PAYMENT: 'PACKAGE_PAYMENT',
    PACKAGE_SELECTION: 'PACKAGE_SELECTION',
    TRANSACTION_PAYMENT: 'TRANSACTION_PAYMENT',
    REFUND: 'REFUND',

 
};

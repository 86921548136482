import { Learner } from 'src/app/models/learner.model';
import { Person } from '../../models/person.model';
import { Transaction } from '../../models/transaction.model';

interface PersonCreateInput {
    id: string;
    awsToken: string; // required
    firstName: string; // required
    familyName: string; // required
    middleName: string; // optional
    phone: string; // required
    email: string; // required
    aboutMe: string;
    imageAvatarURL: string;
    dateOfBirth: Date;
    systemRole: string; // required
    completedSetup: boolean;
    county: {
        connect: { id: string }
    };
}

interface PersonUpdateInput {
    awsToken: string;
    firstName: string;
    familyName: string;
    middleName: string;
    phone: string;
    email: string;
    aboutMe: string;
    imageAvatarURL: string;
    dateOfBirth: Date;
    systemRole: string;
    completedSetup: boolean;
    county: {
        connect: { id: string }
    };
}

interface AccountUpsertNestedInput {
    transactionAccount: {
        upsert: {
            update: { transactions: { create: TransactionCreateInput[] } },
            create: {
                id: string,
                transactions: { create: TransactionCreateInput[] }
            },
        }
    };
}

interface TransactionCreateInput {
    id: string;
    type: string;
    status: string;
    Amount: number;
    metaInformation: string;
    connectedHours: number;
    learner: { connect: { id: string } };
}

export class PersonMapperUtil {
    static PersonToPersonCreateInput(person: Person): PersonCreateInput {
        return {
            id: person.id,
            awsToken: person.awsToken,
            firstName: person.firstName,
            familyName: person.familyName,
            middleName: person.middleName,
            phone: person.phone,
            email: person.email,
            aboutMe: person.aboutMe,
            imageAvatarURL: person.imageAvatarURL,
            dateOfBirth: person.dateOfBirth,
            systemRole: person.systemRole,
            completedSetup: person.completedSetup,
            county: !!person.county && !!person.county.id ? {connect: {id: person.county.id}} : null
        };
    }

    static PersonToPersonUpdateInput(person: Person): PersonUpdateInput {
        return {
            awsToken: person.awsToken,
            firstName: person.firstName,
            familyName: person.familyName,
            middleName: person.middleName,
            phone: person.phone,
            email: person.email,
            aboutMe: person.aboutMe,
            imageAvatarURL: person.imageAvatarURL,
            dateOfBirth: person.dateOfBirth,
            systemRole: person.systemRole,
            completedSetup: person.completedSetup,
            county: !!person.county && !!person.county.id ? {connect: {id: person.county.id}} : null
        };
    }

    static PersonTransactionsToPersonUpdateInput(person: Person): AccountUpsertNestedInput {
        return {
            transactionAccount: {
                upsert: {
                    update: {
                        transactions: {
                            create: person.transactionAccount.transactions.map(this.TransactionsToTransactionCreateInput)
                        }
                    },
                    create: {
                        id: !!person.transactionAccount.id ?
                            person.transactionAccount.id :
                            (Math.random()).toString(36).substring(2) + (Math.random()).toString(36).substring(2),
                        transactions: { create: person.transactionAccount.transactions.map(this.TransactionsToTransactionCreateInput) }
                    }
                }
            }
        } as AccountUpsertNestedInput;
    }

    static TransactionsToTransactionCreateInput(transaction: Transaction): TransactionCreateInput {
      const  data ={
        id: transaction.id,
        Amount: transaction.Amount,
        type: transaction.type,
        status: transaction.status,
        connectedHours: transaction.connectedHours,
        metaInformation: transaction.metaInformation,
        learner: undefined
    }
    if(transaction.learner){
        data.learner = {connect: {id: transaction.learner.id}}
    }
        return data as TransactionCreateInput;
    }

}

import { Learner } from '../../models/learner.model';
import { DrivingLesson } from '../../models/driving-lesson.model';
interface DrivingLessonCreateInput {
      id: string;
    createdAt: Date;
    updatedAt: Date;
 
    learner:Learner,
   

};

interface DrivingLessonUpdateInput {
   id: string;
    createdAt: Date;
    updatedAt: Date;
    learner: { connect: { id: string } };
};

export class DrivingLessonMapperUtil {
   static BookingToBookingCreateInput(theoryBooking: DrivingLesson): DrivingLessonCreateInput {
      const data: any = {
      
         // learner:Learner
      };
      return data;
   }

   static BookingToBookingUpdateInput(theoryBooking: DrivingLesson): DrivingLessonUpdateInput {
      const data: any = {
         
      };
      if (theoryBooking.learner && theoryBooking.learner.id) {
         data.learner = { connect: { id: theoryBooking.learner.id } };
      }
      if (!theoryBooking.learner) {
         data.learner = { disconnect: true };
      }
      
      return data;
   }
}


import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Observable, } from 'rxjs';
import { ApolloQueryResult } from 'apollo-client';
import { Apollo } from 'apollo-angular';
import { FetchResult } from 'apollo-link';
import { SwapBooking } from '../models/swap-booking.model';
import { SwapBookingMapperUtil } from '../shared/mapper/swap-booking-mapper-util';

export const bookingFields = `
    swapDate
`;

@Injectable()
export class SwapBookingRepositoryService {

    constructor(private readonly apollo: Apollo) { }
    getAllBookings(pageLimit?: number, searchQuery?: string, skip = 0): Observable<FetchResult<any>> {
        
        return this.apollo.query({
            query: gql`
                query allBookings($first: Int, $skip: Int ${!!searchQuery ? ', $searchQuery: String' : ''}) {
                    bookings(first: $first, skip: $skip ${!!searchQuery ? ', where: {name_contains: $searchQuery}' : ''}) {
                        ${bookingFields}
                    }
                }`,
            variables: {
                searchQuery,
                skip,
                first: !!pageLimit ? pageLimit : 100000,
                force: false
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }

    createSwapHistory(swapBooking: SwapBooking): Observable<FetchResult<SwapBooking>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation createSwapHistory($swapHistory: SwapHistoryCreateInput!) {
                    createSwapHistory(data: $swapHistory) {
                       
                        swapDate
                        swapWith {
                            learner{
                                person{
                                    firstName
                                    familyName
                                    middleName
                                }
                                licence {
                                    id
                                    number   
                                }
                            }
                        }
                        booking {
                            learner{
                                person{
                                    firstName
                                    familyName
                                    middleName
                                }
                            }
                        }
                    
                    }
                }`,
            variables: {
                swapHistory: SwapBookingMapperUtil.BookingToBookingCreateInput(swapBooking),
            },
            errorPolicy: 'all'
        });
    }
}

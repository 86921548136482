import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { SignInComponent } from './layouts/sign-in/sign-in.component';
import { UserRouteAccessService } from './shared/auth/user-route-access-service';

export const AppRoutes: Routes = [
    {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [UserRouteAccessService],
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
            }, {
                path: 'setup',
                loadChildren: () => import('./setup/setup.module').then(m => m.SetupModule)
            }, {
                path: 'stakeholder-management',
                loadChildren: () => import('./stakeholders-management/stakeholders-management.module')
                    .then(m => m.StakeholdersManagementModule)
            }, {
                path: 'test-bookings',
                loadChildren: () => import('./test-booking/test-booking.module')
                    .then(m => m.TestBookingModule)
            }, {
                path: 'test-bookings-management',
                loadChildren: () => import('./test-booking-management/test-booking-management.module')
                    .then(m => m.TestBookingManagementModule)
            }, 
            {
                path: 'offline-test-bookings',
                loadChildren: () => import('./offline-booking/offline-test-booking.module')
                    .then(m => m.OfflineTestBookingModule)
            },
            {
                path: 'offline-other-test-bookings',
                loadChildren: () => import('./offline-other-booking/offline-other-test-booking.module')
                    .then(m => m.OfflineOtherTestBookingModule)
            }, 
            {
                path: 'upload',
                loadChildren: () => import('./shared/upload/upload.module').then(m => m.UploadModule)
            }, {
                path: 'applications',
                loadChildren: () => import('./applications/application.module').then(m => m.ApplicationModule)
            }, {
                path: 'accounts',
                loadChildren: () => import('./accounts/account.module').then(m => m.AccountModule)
            }
        ]
    },
    {
        path: 'login',
        component: SignInComponent
    }
];

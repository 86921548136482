import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Observable, } from 'rxjs';
import { ApolloQueryResult } from 'apollo-client';
import { Apollo } from 'apollo-angular';
import { FetchResult } from 'apollo-link';
import { DrivingLessonMapperUtil } from '../shared/mapper/driving-lesson-mapper-util';
import { DrivingLesson } from '../models/driving-lesson.model';

export const drivingLessonFields = `
    id
    createdAt
    updatedAt
    oneHourAmount
    noOfHours
    drivingTestCarRentalCharge
    typeOfTest
    total
    deposit
    finalPaymentToADI
    status
    learner {
        id
        person {
            id
            firstName
            middleName
            familyName
            phone 
            email
        }
        licence {
            id
            number
        }
        instructor{
            id
            person {
                id
                firstName
                familyName
                email
                phone
            }
        }

    }
`;

@Injectable()
export class DrivingLessonsRepositoryService {

    constructor(private readonly apollo: Apollo) { }
    getAllDrivingLessons(pageLimit?: number, searchQuery?: string, skip = 0): Observable<FetchResult<any>> {
        return this.apollo.query({
            query: gql`
                query allDrivingLessons($first: Int, $skip: Int ${!!searchQuery ? ', $searchQuery: String' : ''}) {
                    drivingLessons(first: $first, skip: $skip ${!!searchQuery ? ', where: {name_contains: $searchQuery}' : ''}) {
                        ${drivingLessonFields}
                    }
                }`,
            variables: {
                searchQuery,
                skip,
                first: !!pageLimit ? pageLimit : 100000,
                force: false
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }

    getAllDrivingLessonsByType(type: string, stockType: string, pageLimit?: number, searchQuery?: string, skip = 0): Observable<FetchResult<any>> {
        return this.apollo.query({
            query: gql`
                query allDrivingLessonsByType(
                  
                    $first: Int, 
                    $skip: Int 
                    ${!!searchQuery ? ', $searchQuery: String' : ''}) {
                    drivingLessons(first: $first, skip: $skip,
                    where: {
                        AND: [
                           
                            ${!!searchQuery ? ', {learner: {person: {firstName_contains: $searchQuery}}}' : ''}
                        ]
                    }) {
                        ${drivingLessonFields}
                    }
                }`,
            variables: {
            
                searchQuery,
                skip,
                first: !!pageLimit ? pageLimit : 100000,
                force: false
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }

    createDrivingLesson(theoryBooking: DrivingLesson): Observable<FetchResult<DrivingLesson>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation createDrivingLesson($theoryBooking: DrivingLessonCreateInput!) {
                    createDrivingLesson(data: $theoryBooking) {
                        ${drivingLessonFields}
                    }
                }`,
            variables: {
                theoryBooking: DrivingLessonMapperUtil.BookingToBookingCreateInput(theoryBooking),
            },
            errorPolicy: 'all'
        });
    }

    updateDrivingLesson(theoryBooking: DrivingLesson): Observable<FetchResult<DrivingLesson>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation updateDrivingLesson($theoryBooking: DrivingLessonUpdateInput!, $theoryBookingId: ID!) {
                    updateDrivingLesson(data: $theoryBooking, where: {id: $theoryBookingId}) {
                        ${drivingLessonFields}
                    }
                }`,
            variables: {
                theoryBooking: DrivingLessonMapperUtil.BookingToBookingUpdateInput(theoryBooking),
                theoryBookingId: theoryBooking.id
            },
            errorPolicy: 'all'
        });
    }

    getDrivingLesson(id: string): Observable<ApolloQueryResult<any>> {
        return this.apollo.query({
            query: gql`
                query getDrivingLessonById($bookingId: ID!) {
                    drivingLessons(where: {id: $bookingId}) {
                        ${drivingLessonFields}
                    }
                }`,
            variables: { bookingId: id },
            errorPolicy: 'all'
        });
    }

    deleteDrivingLesson(id: string): Observable<FetchResult<DrivingLesson>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation deleteDrivingLessonById($bookingId: ID!) {
                    deleteDrivingLesson(where: {id: $bookingId}) {
                        id
                    }
                }`,
            variables: { bookingId: id },
            errorPolicy: 'all'
        });
    }
}

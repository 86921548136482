import { Component, EventEmitter, Input, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CustomAlertType } from './custom-alert-type';

@Component({
    selector: 'app-alert',
    templateUrl: 'alert.component.html',
    animations: [
        trigger('showHide', [
            state('show', style({
                transform: 'translateY(6vh)',
            })),
            state('hide', style({
                transform: 'translateY(-100vh)',
            })),
            transition('show => hide', [
                animate('0.5s ease-in'),
            ]),
            transition('hide => show', [
                animate('0.5s ease-out')
            ]),
        ]),
    ],
})
export class AlertComponent {
    @Input() type = '';
    @Input() message = '';
    @Input() timeoutLength = 2000;
    @Output() isDone = new EventEmitter();
    alertIteration = 0;
    timeout = null;
    alertType = new CustomAlertType();

    @Input()
    set showAlert(iteration: number) {
        if (iteration > 0) {
            this.alertIteration = iteration;
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.alertDone();
            }, this.timeoutLength);
        } else {
            clearTimeout(this.timeout);
            this.alertDone();
        }
    }

    alertDone() {
        this.alertIteration = 0;
        this.isDone.emit(0);
    }

}

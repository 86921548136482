import { TestCentre } from '../../models/test-centre.model';

interface TestCentreCreateInput {
    id: string;
    name: string;
    addressLine1: string;
    addressLine2: string;
    postCode: string;
    city: { connect: { id: string } };
    instructorRate: number;
    type: string;
    subscriptionId:string;
}

interface TestCentreUpdateInput {
    name: string;
    addressLine1: string;
    addressLine2: string;
    postCode: string;
    city: { connect: { id: string } };
    instructorRate: number;
    type: string;
    subscriptionId:string;

    
}

export class TestCentreMapperUtil {
     static TestCentreToTestCentreCreateInput(testCentre: TestCentre): TestCentreCreateInput {
         return {
             id: testCentre.id,
             name: testCentre.name,
             addressLine1: testCentre.addressLine1,
             addressLine2: testCentre.addressLine2,
             postCode: testCentre.postCode,
             city: { connect: { id: testCentre.city.id } },
             instructorRate: testCentre.instructorRate,
             type: testCentre.type,
             subscriptionId:testCentre.subscriptionId,
         };
     }

     static TestCentreToTestCentreUpdateInput(testCentre: TestCentre): TestCentreUpdateInput {
         return {
             name: testCentre.name,
             addressLine1: testCentre.addressLine1,
             addressLine2: testCentre.addressLine2,
             postCode: testCentre.postCode,
             city: { connect: { id: testCentre.city.id } },
             instructorRate: testCentre.instructorRate,
             type: testCentre.type,
             subscriptionId:testCentre.subscriptionId,
         };
     }
}

export class CustomAlertType {
    SUCCESS = 'success';
    DANGER = 'danger';
    WARNING = 'warning';
    INFO = 'info';
    PRIMARY = 'primary';
    AMBER = 'amber';
}

export const CustomAlertTypeConst = {
    SUCCESS: 'success',
    DANGER: 'danger',
    WARNING: 'warning',
    INFO: 'info',
    PRIMARY: 'primary',
    AMBER: 'amber',
};

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular/dist/src/providers/amplify.service';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { CognitoService } from '../service/cognito.service';
import { User } from '../models/user.model';
import { Person } from '../models/person.model';
import { PersonRepositoryService } from '../service/person.service';
import { Notification } from '../models/notification.model';
import { NotificationRepositoryService } from '../service/notification.service';

@Component({
    selector: 'app-notification',
    templateUrl: 'notification.component.html',
})

export class NotificationComponent implements OnInit {
    cognitoUser: User = new User();
    currentUser: Person = new Person();
    notifications: Notification[] = [];
    @Input() isOpen: boolean;
    @Output() isClosed = new EventEmitter();
    @Output() notificationsUpdate = new EventEmitter();

    constructor(private readonly amplifyService: AmplifyService,
                private readonly router: Router,
                private readonly notificationService: NotificationRepositoryService,
                private readonly cognitoService: CognitoService,
                private readonly personService: PersonRepositoryService) {}

    ngOnInit(): void {
        this.loadCurrentUser();
    }

    loadCurrentUser() {
        Auth.currentUserInfo().then((user) => {
            const attributes = user.attributes;
            this.cognitoUser = new User(attributes.sub, '', attributes.name, attributes.family_name, '', attributes.email);

            this.personService.getPersonByAwsId(this.cognitoUser.id).subscribe(result => {
                this.currentUser = result.data.person;
            });
        });
    }

    toggle() {
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
            this.loadCurrentUser();
        } else {
            this.close();
        }
    }

    outOfFocusClick() {
        this.isOpen = false;
        this.close();
    }

    close() {
        this.isClosed.emit(false);
    }

    @Input()
    set notificationsEvent(event) {
        this.notifications = event;
    }

    clearNotification(notify: Notification) {
        notify.readDate = new Date();
        const notificationElement = document.getElementById(notify.id);
        notificationElement.style.transform = 'translate(500px, 0px)';
        setTimeout(() => {
            notificationElement.style.height = '0';
            notificationElement.style.minHeight = '0';
            notificationElement.style.padding = '0';
            notificationElement.style.margin = '0';

            this.notificationService.updateNotification(notify).subscribe((res) => {
                if (!res.errors) {
                    this.notifications = this.notifications.filter(message => message.id !== notify.id);
                    notificationElement.remove();
                    this.notificationsUpdate.emit(this.notifications);
                }
            });
        }, 300);
    }
}

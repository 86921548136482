import * as moment from 'moment';
import { Learner } from '../../models/learner.model';
import { TheoryBooking } from '../../models/theory-booking.model';
interface TheoryBookingCreateInput {
      id: string;
    createdAt: Date;
    updatedAt: Date;
    theoryTestDate: Date
    theoryTestCentre: string
    theoryTestPrice: string
    theoryTestAdmin: string
    theoryTestVat: string
    theoryTestTransactionFee: string
    theoryTestStatus:string
    reservedUntil: string
    reservedId: string
    learner:Learner,
    note:string

};

interface TheoryBookingUpdateInput {
   id: string;
    createdAt: Date;
    updatedAt: Date;
    theoryTestDate: Date
    theoryTestCentre: string
    theoryTestPrice: string
    theoryTestAdmin: string
    theoryTestVat: string
    theoryTestTransactionFee: string
    theoryTestStatus:string
    reservedUntil: string
    reservedId: string
    note:string
    learner: { connect: { id: string } };
};

export class TheoryBookingMapperUtil {
   static BookingToBookingCreateInput(theoryBooking: TheoryBooking): TheoryBookingCreateInput {
      const data: any = {
         theoryTestDate: moment(theoryBooking.theoryTestDate).format("YYYY-MM-DD HH:mm"),
         theoryTestCentre: theoryBooking.theoryTestCentre,
         theoryTestPrice: theoryBooking.theoryTestPrice,
         theoryTestAdmin: theoryBooking.theoryTestAdmin,
         theoryTestVat: theoryBooking.theoryTestVat,
         theoryTestTransactionFee: theoryBooking.theoryTestTransactionFee,
         theoryTestStatus:theoryBooking.theoryTestStatus,
         reservedUntil: theoryBooking.reservedUntil,
         reservedId: theoryBooking.reservedId,
         note:theoryBooking.note
         // learner:Learner
      };
      return data;
   }

   static BookingToBookingUpdateInput(theoryBooking: TheoryBooking): TheoryBookingUpdateInput {
      const data: any = {
         theoryTestDate: moment(theoryBooking.theoryTestDate).format("YYYY-MM-DD HH:mm"),
         theoryTestCentre: theoryBooking.theoryTestCentre,
         theoryTestPrice: theoryBooking.theoryTestPrice,
         theoryTestAdmin: theoryBooking.theoryTestAdmin,
         theoryTestVat: theoryBooking.theoryTestVat,
         theoryTestTransactionFee: theoryBooking.theoryTestTransactionFee,
         theoryTestStatus:theoryBooking.theoryTestStatus,
         reservedUntil: theoryBooking.reservedUntil,
         reservedId: theoryBooking.reservedId,
         note:theoryBooking.note,
         
      };
      if (theoryBooking.learner && theoryBooking.learner.id) {
         data.learner = { connect: { id: theoryBooking.learner.id } };
      }
      if (!theoryBooking.learner) {
         data.learner = { disconnect: true };
      }
      
      return data;
   }
}

import { Conversation } from 'src/app/models/conversation.model';
import { Person } from 'src/app/models/person.model';
import { Message } from 'src/app/models/message.model';
import { connect } from 'net';

interface ConversationCreateInput {
    id: string;
    name: string;
    type: string;
    members: {
        connect: [{id: string}, {id: string}]
    };
    author: { connect: { id: string } };
    messages: Message[];
}

interface ConversationUpdateInput {
    name: string;
    messages: { create: [{ text: string, read: boolean, author: { connect: { id: string }}}] };
}

export class ConversationMapperUtil {
     static ConversationToConversationCreateInput(conversation: Conversation): ConversationCreateInput {
         return {
             id: conversation.id,
             type: conversation.type,
             name: conversation.name,
             author: { connect: { id: conversation.author.id } },
             members: { connect: [ {id: conversation.members[0].id}, { id: conversation.members[1].id } ] },
             messages: null
         };
     }

     static ConversationToConversationUpdateInput(conversation: Conversation): ConversationUpdateInput {
        const msg = conversation.messages.pop();
         return {
            name: conversation.name,
            messages: { create: [{ text: msg.text,
                read: false, author: { connect: { id: msg.author.id } } }]}
         };
     }
}

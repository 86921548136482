export class InputUtil {

    static getValidString(input: string) {
        if (input) {
            input = input.toString().trim();
        }
        return (!!input && input !== '') ? input.toString().trim() : '';
    }

    static toTitleCase(text: string): string {
        return text.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    static camelCaseToTitleCase(text: string): string {
        const result = text.replace( /([A-Z])/g, ' $1' );
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    static kebabCaseToNormalCase(text: string): string {
        return text.replace(/([_-])/g, ' ');
    }

    static slashToNormalCase(text: string): string {
        return text.replace(/([`/])/g, ' ');
    }

    static propKeyToTitle(text: string): string {
        return this.toTitleCase(text.replace('.', ' '));
    }

    static mapDeepKeysToObject(object: any, deepKey: string): any {
        return deepKey.split('.').reduce((prev, curr) => {
            return prev ? prev[curr] : null;
        }, object || self);
    }


    static setDate(date: Date, time: string): Date {
        if (!!date && !!time && time.length > 0) {
            const timeArray = time.split(':');
            const newDate = new Date(date); // Reinitialisation must be done for some reason. Throws setUTCHours is not a function
            newDate.setHours(+timeArray[0], +timeArray[1], 0, 0);
            return newDate;
        }
        return null;
    }

    static getTimeInputForDate(loadedDate: Date): string {
        if (!!loadedDate) {
            const newDate = new Date(loadedDate); // Throws getUTCHours is not a function
            return [
                this.setFrontDigit(newDate.getHours().toString()),
                this.setFrontDigit(newDate.getMinutes().toString())
            ].join(':');
        }
        return null;
    }

    static setFrontDigit(timeValue: string) {
        return timeValue.length === 1 ? '0' + timeValue : timeValue;
    }
}

import { SwapBooking } from '../../models/swap-booking.model';

interface SwapBookingCreateInput {
   id: string;
   booking: { connect: { id: string } };
   swapWith: { connect: { id: string } };
   swapDate:string;
};

interface SwapBookingUpdateInput {
   booking: { connect: { id: string } };
   swapWith: { connect: { id: string } };
   swapDate:string;
};

export class SwapBookingMapperUtil {
   static BookingToBookingCreateInput(booking: SwapBooking): SwapBookingCreateInput {
      const data: any = {
         booking: { connect: { id: booking.booking } },
         swapWith: { connect: { id: booking.swapWith } },
         swapDate:booking.swapDate

      };
      return data;
   }

   static BookingToBookingUpdateInput(booking: SwapBooking): SwapBookingUpdateInput {
      
      const data: any = {
         booking: { connect: { id: booking.booking } },
         swapWith:{ connect: { id: booking.swapWith } },
         swapDate:booking.swapDate
      };
      return data;
   }
}

import * as moment from 'moment';
import { Booking } from '../../models/booking.model';

const WEEKDAY_FEE = 62.00;
const WEEKEND_FEE = 75.00;

interface BookingCreateInput {
   id: string;
   bookingFee: number;
   provincialLicenseNumber: string;
   bookingProfile: string;
   bookingDate: Date;
   confirmedDate: Date;
   cancellationDate: Date;
   type: string;
   stockType: string;
   learner: { connect: { id: string } };
   testCentre: { connect: { id: string } };
   metaLocation:string;
   metaDate:Date;

};

interface BookingUpdateInput {
   bookingFee: number;
   provincialLicenseNumber: string;
   bookingProfile: string;
   bookingDate: Date;
   confirmedDate: Date;
   cancellationDate: Date;
   type: string;
   stockType: string;
   learner: { connect: { id: string } };
   testCentre: { connect: { id: string } };
};

export class BookingMapperUtil {
   static BookingToBookingCreateInput(booking: Booking): BookingCreateInput {
      const data: any = {
         bookingFee: booking.bookingFee,
         provincialLicenseNumber: booking.provincialLicenseNumber,
         bookingProfile: booking.bookingProfile,
         bookingDate: moment(booking.bookingDate).format("YYYY-MM-DD HH:mm"),
         cancellationDate: booking.cancellationDate,
         passResult: booking.passResult,
         type: booking.type,
         stockType: booking.stockType,
         bookingReference: booking.bookingReference,
         lockedWith:booking.lockedWith,
         metaLocation:booking.metaLocation,
         metaDate:booking.metaDate,
         reservedId:'0',
         reservedUntil:0
      };
      if (booking.learner && booking.learner.id) {
         data.learner = { connect: { id: booking.learner.id } };
      }

      if (!data.learner && booking.bookedLearner.firstName !== null) {

         data.learner = {
            create: {
               awsToken: booking.provincialLicenseNumber,
               person: {
                  create: {
                     firstName: booking.bookedLearner.firstName,
                     familyName: booking.bookedLearner.surname,
                     middleName: booking.bookedLearner.middleName,
                     awsToken: booking.provincialLicenseNumber,
                     phone: booking.bookedLearner.mobile,
                     email: booking.bookedLearner.email,
                     systemRole: 'Learner'
                  }
               },
               licence: {
                  create: {
                     firstName: booking.bookedLearner.firstName,
                     lastName: booking.bookedLearner.surname,
                     number: booking.provincialLicenseNumber,
                     expiryDate: undefined,
                  }
               }
            }
         }

      }
      if (booking.testCentre && booking.testCentre.id) {
         data.testCentre = { connect: { id: booking.testCentre.id } };
      }
      return data;
   }

   static BookingToBookingUpdateInput(booking: Booking): BookingUpdateInput {

      const bookingDate = moment(booking.bookingDate);
      const isWeekend = bookingDate.day() === 6 || bookingDate.day() === 0;
      
      const data: any = {
         provincialLicenseNumber: booking.provincialLicenseNumber,
         bookingProfile: booking.bookingProfile,
         bookingDate: moment(booking.bookingDate).format("YYYY-MM-DD HH:mm"),
         bookingPaid: booking.bookingPaid,
         passResult: booking.passResult,
         bookedLearner: booking.bookedLearner,
         confirmedDate: booking.confirmedDate,
         cancellationDate: booking.cancellationDate,
         type: booking.type,
         stockType: booking.stockType,
         bookingReference: booking.bookingReference,
         eligibleDate: booking.eligibleDate,
         note : booking.note,
         lockedWith:booking.lockedWith,
         startDateWeeks:booking.startDateWeeks
      };
      if (booking.swapToLearner && booking.swapToLearner.id) {
         data.swapToLearner = { connect: { id: booking.swapToLearner.id } }
      }
      if (booking.learner && booking.learner.id) {
         data.learner = { connect: { id: booking.learner.id } };
      }
      if (booking.originalLearner && booking.originalLearner) {
         data.learner = { disconnect: true };
      }
      if (booking.testCentre && booking.testCentre.id) {
         data.testCentre = { connect: { id: booking.testCentre.id } };
      }
      // if (booking.note ) {
      //    data.note = booking.note;
      // }

      if (booking.type === "FOR_SALE") {
         data.bookingFee = booking.bookingFee ? parseFloat(booking.bookingFee.toString()) : (isWeekend ? WEEKEND_FEE : WEEKDAY_FEE);
      } else {
         data.bookingFee = booking.bookingFee;
      }

      console.log('data bookings ===>>> ', data)
      return data;
   }
}

import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Observable, } from 'rxjs';
import { ApolloQueryResult } from 'apollo-client';
import { Apollo } from 'apollo-angular';
import { FetchResult } from 'apollo-link';
import { PassPlusMapperUtil } from '../shared/mapper/pass-plus-mapper-util';
import { PassPlus } from '../models/pass-plus.model';
export const passPlusFields = `
    id
    createdAt
    updatedAt
    courseAmount
    noOfHours
    depositAmount
    payToInstructor
    status
    learner {
        id
        person {
            id
            firstName
            middleName
            familyName
            phone 
            email
        }
        licence {
            id
            number
        }
        instructor{
            id
            person {
                id
                firstName
                familyName
                email
                phone
            }
        }

    }
`;

@Injectable()
export class PassPlusRepositoryService {

    constructor(private readonly apollo: Apollo) { }
    getAllPassPlus(pageLimit?: number, searchQuery?: string, skip = 0): Observable<FetchResult<any>> {
        return this.apollo.query({
            query: gql`
                query allPassPlus($first: Int, $skip: Int ${!!searchQuery ? ', $searchQuery: String' : ''}) {
                    passPluses(first: $first, skip: $skip ${!!searchQuery ? ', where: {name_contains: $searchQuery}' : ''}) {
                        ${passPlusFields}
                    }
                }`,
            variables: {
                searchQuery,
                skip,
                first: !!pageLimit ? pageLimit : 100000,
                force: false
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }

    getAllPassPlusByType(type: string, stockType: string, pageLimit?: number, searchQuery?: string, skip = 0): Observable<FetchResult<any>> {
        return this.apollo.query({
            query: gql`
                query allPassPlusByType(
                  
                    $first: Int, 
                    $skip: Int 
                    ${!!searchQuery ? ', $searchQuery: String' : ''}) {
                    passPlus(first: $first, skip: $skip,
                    where: {
                        AND: [
                           
                            ${!!searchQuery ? ', {learner: {person: {firstName_contains: $searchQuery}}}' : ''}
                        ]
                    }) {
                        ${passPlusFields}
                    }
                }`,
            variables: {
            
                searchQuery,
                skip,
                first: !!pageLimit ? pageLimit : 100000,
                force: false
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }

    createPassPlus(passPlus: PassPlus): Observable<FetchResult<PassPlus>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation createPassPlus($passPlus: PassPlusCreateInput!) {
                    createPassPlus(data: $passPlus) {
                        ${passPlusFields}
                    }
                }`,
            variables: {
                passPlus: PassPlusMapperUtil.BookingToBookingCreateInput(passPlus),
            },
            errorPolicy: 'all'
        });
    }

    updatePassPlus(passPlus: PassPlus): Observable<FetchResult<PassPlus>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation updatePassPlus($passPlus: PassPlusUpdateInput!, $passPlusId: ID!) {
                    updatePassPlus(data: $passPlus, where: {id: $passPlusId}) {
                        ${passPlusFields}
                    }
                }`,
            variables: {
                passPlus: PassPlusMapperUtil.BookingToBookingUpdateInput(passPlus),
                passPlusId: passPlus.id
            },
            errorPolicy: 'all'
        });
    }

    getPassPlus(id: string): Observable<ApolloQueryResult<any>> {
        return this.apollo.query({
            query: gql`
                query getPassPlusById($bookingId: ID!) {
                    passPlus(where: {id: $bookingId}) {
                        ${passPlusFields}
                    }
                }`,
            variables: { bookingId: id },
            errorPolicy: 'all'
        });
    }

    deletePassPlus(id: string): Observable<FetchResult<PassPlus>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation deletePassPlusById($bookingId: ID!) {
                    deletePassPlus(where: {id: $bookingId}) {
                        id
                    }
                }`,
            variables: { bookingId: id },
            errorPolicy: 'all'
        });
    }
}

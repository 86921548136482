export class County {
    public id?: any;
    public name?: string;
    public description?: string;

    constructor(
        id?: any,
        name?: any,
        description?: any
    ) {
        this.id = id ? id : null;
        this.name = name ? name : null;
        this.description = description ? description : null;
    }
}

import {Injectable} from '@angular/core';
import gql from 'graphql-tag';
import { Observable, } from 'rxjs';
import { ApolloQueryResult } from 'apollo-client';
import { Apollo,  } from 'apollo-angular';
import { FetchResult } from 'apollo-link';
import { Person } from '../models/person.model';
import { PersonMapperUtil } from '../shared/mapper/person-mapper-util';
import { User } from '../models/user.model';

@Injectable()
export class PersonRepositoryService {

    constructor(private readonly apollo: Apollo) {}

    getAllPersons(pageLimit?: number, skip = 0): Observable<FetchResult<any>> {
        return this.apollo.query({
            query: gql`
                query allPersons($first: Int, $skip: Int) {
                    persons(first: $first, skip: $skip) {
                        id
                        awsToken
                        firstName
                        familyName
                        phone
                        email
                        aboutMe
                        imageAvatarURL
                        dateOfBirth
                        systemRole
                        completedSetup
                        county {
                            id
                            name
                        }
                    }
                }`,
            variables: {
                skip,
                first: !!pageLimit ? pageLimit : 1000000,
                force: false
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }

    getAllPersonsByRole(systemRole: string, pageLimit?: number, searchQuery?: string, skip = 0): Observable<FetchResult<any>> {
        return this.apollo.query({
            query: gql`
                query allPersons($systemRole: SystemRole!, $first: Int, $skip: Int ${!!searchQuery ? ', $searchQuery: String' : ''}) {
                    persons(where: {
                        AND: [
                            {systemRole: $systemRole}
                            ${!!searchQuery ? ', {firstName_contains: $searchQuery}' : ''}
                        ]
                    }, first: $first, skip: $skip) {
                        id
                        awsToken
                        firstName
                        familyName
                        phone
                        email
                        aboutMe
                        imageAvatarURL
                        dateOfBirth
                        systemRole
                        completedSetup
                        county {
                            id
                            name
                        }
                    }
                }`,
            variables: {
                systemRole,
                searchQuery,
                skip,
                first: !!pageLimit ? pageLimit : 1000,
                force: false
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }

    getAllPersonsByRoleForDropDowns(systemRole: string): Observable<FetchResult<any>> {
        return this.apollo.query({
            query: gql`
                query allPersons($systemRole: SystemRole!, $first: Int, $skip: Int) {
                    persons(where: {systemRole: $systemRole}, first: $first, skip: $skip) {
                        id
                        firstName
                        familyName
                        email
                        systemRole
                    }
                }`,
            variables: {
                systemRole,
                first: 1000000,
                force: false
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }

    getAllPersonsByRoleSlim(systemRole: string): Observable<FetchResult<any>> {
        return this.apollo.query({
            query: gql`
                query allPersons($systemRole: SystemRole!, $first: Int, $skip: Int) {
                    persons(where: {systemRole: $systemRole}, first: $first, skip: $skip) {
                        id
                        systemRole
                    }
                }`,
            variables: {
                systemRole,
                first: 1000000,
                force: false
            },
            fetchPolicy: 'cache-first',
            errorPolicy: 'all'
        });
    }

    createPerson(person: Person): Observable<FetchResult<Person>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation createPerson($person: PersonCreateInput!) {
                    createPerson(data: $person) {
                        id
                        awsToken
                        firstName
                        familyName
                        phone
                        email
                        aboutMe
                        imageAvatarURL
                        dateOfBirth
                        systemRole
                        completedSetup
                        county {
                            id
                            name
                        }
                    }
                }`,
            variables: {
                person: PersonMapperUtil.PersonToPersonCreateInput(person),
            },
            errorPolicy: 'all'
        });
    }

    updatePerson(person: Person): Observable<FetchResult<Person>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation updatePerson($person: PersonUpdateInput!, $personId: ID!) {
                    updatePerson(data: $person, where: {id: $personId}) {
                        id
                        awsToken
                        firstName
                        familyName
                        middleName
                        phone
                        email
                        aboutMe
                        imageAvatarURL
                        dateOfBirth
                        systemRole
                        completedSetup
                        county {
                            id
                            name
                        }
                    }
                }`,
            variables: {
                person: PersonMapperUtil.PersonToPersonUpdateInput(person),
                personId: person.id
            },
            errorPolicy: 'all'
        });
    }

    updatePersonTransactions(person: Person): Observable<FetchResult<Person>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation updatePerson($person: PersonUpdateInput!, $personId: ID!) {
                    updatePerson(data: $person, where: {id: $personId}) {
                        id
                        firstName
                        familyName
                        transactionAccount {
                            id
                        }
                    }
                }`,
            variables: {
                person: PersonMapperUtil.PersonTransactionsToPersonUpdateInput(person),
                personId: person.id
            },
            errorPolicy: 'all'
        });
    }

    getPerson(id: string): Observable<ApolloQueryResult<any>> {
        return this.apollo.query({
            query: gql`
                query getPersonById($personId: ID!) {
                    person(where: { id: $personId}) {
                        id
                        awsToken
                        firstName
                        familyName
                        phone
                        email
                        aboutMe
                        imageAvatarURL
                        dateOfBirth
                        systemRole
                        completedSetup
                        county {
                            id
                            name
                        }
                        transactionAccount {
                            id
                        }
                    }
                }`,
            variables: { personId: id },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }

    getPersonByAwsId(awsToken: string): Observable<ApolloQueryResult<any>> {
        return this.apollo.query({
            query: gql`
                query getPersonByAwsId($personAwsToken: String!) {
                    person(where: { awsToken: $personAwsToken}) {
                        id
                        awsToken
                        firstName
                        familyName
                        phone
                        email
                        aboutMe
                        imageAvatarURL
                        dateOfBirth
                        systemRole
                        completedSetup
                        county {
                            id
                            name
                        }
                    }
                }`,
            variables: { personAwsToken: awsToken },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }

    getPersonByAwsIdAndIsAdmin(awsToken: string): Observable<ApolloQueryResult<any>> {
        return this.apollo.query({
            query: gql`
                query getPersonByAwsId($personAwsToken: String!) {
                    persons(where: {
                        AND: [
                                { awsToken: $personAwsToken},
                                { systemRole: Admin }
                            ]
                    }) {
                        id
                        awsToken
                        firstName
                        familyName
                        phone
                        email
                        aboutMe
                        imageAvatarURL
                        dateOfBirth
                        systemRole
                        completedSetup
                        county {
                            id
                            name
                        }
                    }
                }`,
            variables: { personAwsToken: awsToken },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }

    deletePersonByAwsToken(awsToken: string): Observable<FetchResult<Person>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation deletePersonByAwsToken($personAwsToken: String!) {
                    deletePerson(where: {awsToken: $personAwsToken}) {
                        id
                    }
                }`,
            variables: { personAwsToken: awsToken },
            errorPolicy: 'all'
        });
    }

    mapCognitoUserToPerson(cognitoUser: User): Person {
        return new Person(
            null,
            cognitoUser.id,
            null,
            null,
            cognitoUser.name,
            cognitoUser.familyName,
            null,
            cognitoUser.phoneNumber,
            cognitoUser.email,
            null,
            null,
            null,
            null,
            'Admin',
            true
        );
    }
}

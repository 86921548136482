import { Injectable, OnDestroy } from '@angular/core';
import { ChatRepositoryService } from './chat.service';
import { Conversation } from '../models/conversation.model';
import { Person } from '../models/person.model';
import { PersonRepositoryService } from './person.service';
import { Auth } from 'aws-amplify';
import { SystemRole, ConversationType } from '../models/types';
import { Subscription } from 'rxjs';
import { Observable } from 'apollo-link';
import { SubscriptionResult } from 'apollo-angular';

@Injectable()
export class ChatViewService implements OnDestroy {
    loading: boolean;
    isOpen: boolean;
    selectedConversation: Conversation;
    instructors_conversations: Conversation[] = [];
    learners_conversations: Conversation[] = [];
    currentUser: Person;
    activeTab: number = 0;
    _subscription: Subscription;
    _converstationResult: Observable<SubscriptionResult<any>>
    unread: number = 0;

    constructor(private chatService: ChatRepositoryService, private personService: PersonRepositoryService) {
        this.initialiazeUser();
    }

    initialiazeUser() {
        Auth.currentUserInfo().then((user) => {
            if (user) {
                const attributes = user.attributes;
                this.loading = true;
                this.instructors_conversations = [];
                this.learners_conversations = [];
                this.personService.getPersonByAwsId(attributes.sub).subscribe(r => {
                    console.log("user.attributes",r);
                    this.currentUser = r.data.person;
                    this._subscription = this.chatService.conversationsUpdated(r.data.person.id).subscribe(e => {
                        this.updateConversation(e.data.conversation.node, true);
                    });
                    this.loading = false;
                    this.isOpen = false;
                    this.loadConversations();
                });
            }
        });
    }

    ngOnDestroy() {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    loadConversations() {
        this.loading = true;
        this.chatService.getConversationByMemberId(this.currentUser.id).subscribe(results => {
            results.data.conversations.forEach(conversation => {
                if (conversation.members && conversation.members.length > 1) {
                    this.updateConversation(conversation, true);
                }
            });
            this.loading = false;
        });
    }

    updateConversation(conversation: Conversation, bulkUpdate = false) {
        if (conversation) {
            if (conversation.type === ConversationType.ADMIN_INSTRUCTOR) {
                const index = this.instructors_conversations.findIndex(x => x.id === conversation.id);
                if (index >= 0) {
                    this.instructors_conversations[index] = conversation;
                } else {
                    this.instructors_conversations.push(conversation);
                }
                this.instructors_conversations = JSON.parse(JSON.stringify(this.instructors_conversations));
                this.activeTab = !bulkUpdate ? 0 : this.activeTab;
            } else if (conversation.type === ConversationType.ADMIN_LEARNER) {
                const index = this.learners_conversations.findIndex(x => x.id === conversation.id);
                if (index >= 0) {
                    this.learners_conversations[index] = conversation;
                } else {
                    this.learners_conversations.push(conversation);
                }
                this.learners_conversations = JSON.parse(JSON.stringify(this.learners_conversations));
                this.activeTab = !bulkUpdate ? 1 : this.activeTab;
            }
        }
        this.getUnreadCount();
    }

    getUnreadCount() {
        const unread_instructors = this.instructors_conversations.flatMap(conv => this.getUnreadMessages(conv));
        const unread_learners = this.learners_conversations.flatMap(conv => this.getUnreadMessages(conv));
        this.unread = unread_instructors.length + unread_learners.length;
    }

    getUnreadMessages(converstation) {
        const filter = (msg) => !msg.read && msg.author.id !== this.currentUser.id;
        return converstation.messages.filter(filter);
    }

    markMessagesAsRead(conversation) {
        const unreadMessages = this.getUnreadMessages(conversation).map(msg => msg.id);
        if (unreadMessages.length) {
            const sub = this.chatService.markConversationMessagesAsRead(conversation.id, unreadMessages)
                .subscribe(() => sub.unsubscribe());
        }
    }

    loadConversation(authorId: string, recipientId: string, conversationType: string) {
        this.loading = true;
        this.chatService.getUserConversation(authorId, recipientId, conversationType)
            .subscribe(conversation => {
                if (conversation) {
                    this.updateConversation(conversation.data['userConversation']);
                    this.isOpen = true;
                    this.selectedConversation = conversation.data['userConversation'];
                    this.activeTab = conversationType === ConversationType.ADMIN_LEARNER ? 1 : 0;
                }
                this.loading = false;
                
            });
    }

    sendMessage(conversation: Conversation) {
        this.chatService.updateConversation(conversation).subscribe((result) => {
            this.updateConversation(result.data['updateConversation']);
        });
    }

}

import { Injectable, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { environment } from '../../environments/environment';
import {
    AdminGetUserResponse,
    ListUsersResponse,
    AdminUpdateUserAttributesResponse
} from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { CognitoUtil } from '../shared/utils/cognito-util';
import { User } from '../models/user.model';

@Injectable()
export class CognitoService {

    // Used to initialise a service that's easier to use and avoid duplicate code
    private getCognitoProvider(): Promise<CognitoIdentityServiceProvider> {
        return new Promise((resolve, error) => {
            Auth.currentCredentials().then((credentials) => {
                const cognitoIdentityServiceProvider = new CognitoIdentityServiceProvider({
                    region: environment.region,
                    credentials: Auth.essentialCredentials(credentials)
                });
                resolve(cognitoIdentityServiceProvider);
            }).catch((e) => error(e));
        });
    }

    getCognitoUser(userId: string): Promise<AdminGetUserResponse> {
        return new Promise((resolve, reject) => {
            const params = {
                UserPoolId: environment.UserPoolId,
                Username: userId
            };
            this.getCognitoProvider().then((service) => {
                service.adminGetUser(params, (err, data) => {
                    !!err ? reject(err) : resolve(data);
                });
            }).catch((e) => console.log(e));
        });
    }

    getAllCognitoUsers(searchParams?: any, paginationToken?: string): Promise<ListUsersResponse> {
        return new Promise((resolve, reject) => {
            this.getCognitoProvider().then((service) => {
                const params = {
                    UserPoolId: environment.UserPoolId,
                    Limit: 10,
                };
                if (!!paginationToken && paginationToken.length > 0) {
                    params['PaginationToken'] = paginationToken;
                }
                if (!!searchParams && searchParams.length > 0) {
                    params['Filter'] = searchParams;
                }
                service.listUsers(params, (err, data) => {
                    !!err ? reject(err) : resolve(data);
                });
            }).catch((e) => console.log(e));
        });
    }

    updateCognitoUser(user: User): Promise<AdminUpdateUserAttributesResponse> {
        return new Promise((resolve, reject) => {
            this.getCognitoProvider().then((service) => {
                const params = {
                    UserAttributes: CognitoUtil.mapUserToAttributes(user),
                    UserPoolId: environment.UserPoolId,
                    Username: user.id
                };
                service.adminUpdateUserAttributes(params, (err, data) => {
                    !!err ? reject(err) : resolve(data);
                });
            }).catch((e) => console.log(e));
        });
    }

    createCognitoUser(user: User): Promise<AdminUpdateUserAttributesResponse> {
        return new Promise((resolve, reject) => {
            this.getCognitoProvider().then((service) => {

                const userAttributes = CognitoUtil.mapUserToAttributes(user);
                userAttributes.push({Name: 'email_verified', Value: 'True'});
                userAttributes.push({Name: 'phone_number_verified', Value: 'True'});

                const params = {
                    UserPoolId: environment.UserPoolId, /* required */
                    Username: user.email, /* required */
                    DesiredDeliveryMediums: ['EMAIL', 'SMS'],
                    ForceAliasCreation: false,
                    MessageAction: 'SUPPRESS',
                    TemporaryPassword: environment.tempPass,
                    UserAttributes: userAttributes,
                    //// Will be needed when roles are added
                    // ValidationData: [
                    //     {
                    //         Name: 'STRING_VALUE', /* required */
                    //         Value: 'STRING_VALUE'
                    //     },
                    //     /* more items */
                    // ]
                };

                service.adminCreateUser(params, (err, data) => {
                    !!err ? reject(err) : resolve(data);
                });
            }).catch((e) => console.log(e));
        });
    }

    updateCognitoUserPassword(user: User, newPassword: string, isPermanent: boolean): Promise<AdminUpdateUserAttributesResponse> {
        return new Promise((resolve, reject) => {
            this.getCognitoProvider().then((service) => {
                const params = {
                    Password: newPassword, /* required */
                    UserPoolId: environment.UserPoolId, /* required */
                    Username: user.id, /* required */
                    Permanent: isPermanent
                };
                service.adminSetUserPassword(params, (err, data) => {
                    !!err ? reject(err) : resolve(data);
                });
            }).catch((e) => console.log(e));
        });
    }

    deleteCognitoUser(cognitoUserId: any): Promise<AdminUpdateUserAttributesResponse> {
        return new Promise((resolve, reject) => {
            this.getCognitoProvider().then((service) => {
                const params = {
                    UserPoolId: environment.UserPoolId,
                    Username: cognitoUserId
                };
                service.adminDeleteUser(params, function(err, data) {
                    !!err ? reject(err) : resolve(data);
                });
            }).catch((e) => console.log(e));
        });
    }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as XLSX from 'xlsx';

type AOA = any[][];

@Component({
    selector: 'app-xlsx-reader',
    templateUrl: './xlsx-reader.component.html'
})
export class XlsxReaderComponent implements OnInit {
    @Input() displayResults = true;
    @Output() success = new EventEmitter<any>();

    fileData: AOA = [];
    headerData: AOA = [];
    fileSuccess = false;
    fileError = false;
    invalidFileTypeError = false;
    uploadDisabled = false;

    ngOnInit() {}

    onFileChange(event: any) {
        const target: DataTransfer = event.target as DataTransfer;
        if (target.files.length !== 1) {
            throw new Error('Cannot upload multiple files.');
        }
        const reader: FileReader = new FileReader();

        reader.onload = (e: any) => {
            let binary = '';
            if (reader.readAsBinaryString) {
                binary = e.target.result;
            } else {
                const bytes = new Uint8Array(e.target.result);
                for (let i = 0; i < bytes.length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
            }

            const workbook: XLSX.WorkBook = XLSX.read(binary, { type: 'binary' });

            const worksheetName: string = workbook.SheetNames[0];
            const worksheet: XLSX.WorkSheet = workbook.Sheets[worksheetName];

            const fileContent = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                blankrows: false
            });
            const fileContentCopy = [...fileContent];

            this.headerData = fileContent.shift() as AOA;
            this.fileData = fileContent as AOA;
            this.success.emit(fileContentCopy);

        };
        reader.readAsBinaryString ? reader.readAsBinaryString(target.files[0]) : reader.readAsArrayBuffer(target.files[0]);
    }
}

import { Component } from '@angular/core';
import { ChatViewService } from 'src/app/service/chatview.service';

@Component({
    selector: 'app-chat-modal',
    templateUrl: 'chat-modal.component.html',
    styleUrls: ['chat-modal.component.css'],
})

export class ChatModalComponent {
    constructor(public chatViewService: ChatViewService) {}
    setActiveTab(tab: number) {
        this.chatViewService.activeTab = tab;
    }

    onPress() {
        if (!this.chatViewService.isOpen) {
            this.chatViewService.loadConversations();
        }
        this.chatViewService.isOpen = !this.chatViewService.isOpen;
    }

    outOfFocusClick() {
        this.chatViewService.isOpen = !this.chatViewService.isOpen;
    }
}

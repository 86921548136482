import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { ChatViewService } from 'src/app/service/chatview.service';
import { Message } from 'src/app/models/message.model';
import { Conversation } from 'src/app/models/conversation.model';
import { CustomAlertType } from '../../../alert/custom-alert-type';

@Component({
    selector: 'app-chat-view',
    templateUrl: 'chat-view.component.html',
    styleUrls: ['chat-view.component.css'],
})

export class ChatViewComponent {
    _conversation: Conversation;
    messageText: string;
    loading: boolean;
    alert = 0;
    pageSize = 10;
    type = '';
    message = '';
    alertType = new CustomAlertType();

    @ViewChild('chatMessages', { static: false }) private chatMessages: ElementRef;
    constructor(protected readonly chatViewService: ChatViewService) { }

    @Input()
    set conversation(value) {
        this._conversation = value;
        setTimeout(() => {
            try {
                this.chatMessages.nativeElement.scrollTo({
                    top: this.chatMessages.nativeElement.scrollHeight,
                    behavior: 'smooth'
                });
            } catch (err) { }
        }, 1);
    }

    get conversation() {
        return this._conversation;
    }

    onInputFocus = () => {
        if (this._conversation) {
            this.chatViewService.markMessagesAsRead(this._conversation);
        }
    }

    onPress() {
        this.chatViewService.isOpen = !this.chatViewService.isOpen;
    }

    onSendMessage() {
        if (this.conversation == null || this.conversation === undefined) {
            this.notify('Please select user', this.alertType.INFO);
            return;
        }
        this.conversation.messages = this.conversation.messages || [];
        const author = this.conversation.members.find(x => x.id === this.chatViewService.currentUser.id);
        const newMessage = new Message(null, this.messageText, author, false);
        delete newMessage.id;
        this.conversation.messages.push(newMessage);
        this.chatViewService.sendMessage(this.conversation);
        this.messageText = '';
    }

    notify(errorMessage: string, type: string) {
        this.type = type;
        this.message = errorMessage;
        this.alert++;
        this.loading = false;
    }

}

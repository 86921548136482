import { AttributeType, UserType } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { User } from '../../models/user.model';

export class CognitoUtil {

    static mapCognitoUsersToUser(users: UserType[]): User[] {
        return users.map((user) => {
            return this.mapAttributesToUser(user.Attributes);
        });
    }

    static mapAttributesToUser(attributes: AttributeType[]): User {
        const u = new User();
        attributes.forEach((attribute) => {
            switch (attribute.Name) {
                case 'sub':
                    u.id = attribute.Value;
                    break;
                case 'name':
                    u.name = attribute.Value;
                    break;
                case 'email':
                    u.email = attribute.Value;
                    break;
                case 'phone_number':
                    u.phoneNumber = attribute.Value;
                    break;
                case 'family_name':
                    u.familyName = attribute.Value;
            }
        });
        return u;
    }

    static mapUserToAttributes(user: User): AttributeType[] {
        const attributes: AttributeType[] = [];
        Object.keys(user).forEach(key => {
            switch (key) {
                case 'name':
                    attributes.push({Name: 'name', Value: user[key]});
                    break;
                case 'email':
                    attributes.push({Name: 'email', Value: user[key]});
                    break;
                case 'phoneNumber':
                    attributes.push({Name: 'phone_number', Value: user[key]});
                    break;
                case 'familyName':
                    attributes.push({Name: 'family_name', Value: user[key]});
                    break;
                default:
            }
        });
        return attributes;
    }
}

import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Observable, } from 'rxjs';
import { ApolloQueryResult } from 'apollo-client';
import { Apollo } from 'apollo-angular';
import { FetchResult } from 'apollo-link';
import { TestCentre } from '../models/test-centre.model';
import { TestCentreMapperUtil } from '../shared/mapper/test-centre-mapper-util';

@Injectable()
export class TestCentreRepositoryService {

    constructor(private readonly apollo: Apollo) {}

    getAllTestCentres(pageLimit?: number, searchQuery?: string, skip = 0): Observable<FetchResult<any>> {
        return this.apollo.query({
            query: gql`
                query allTestCentres($first: Int, $skip: Int ${!!searchQuery ? ', $searchQuery: String' : ''}) {
                    testCentres(first: $first, skip: $skip ${!!searchQuery ? ', where: {name_contains: $searchQuery}' : ''}) {
                        id
                        name
                        addressLine1
                        addressLine2
                        postCode
                        subscriptionId
                        city {
                            id
                            name
                            county {
                                id
                                name
                                description
                            }
                        }
                        instructorRate
                        type
                    }
                }`,
            variables: {
                searchQuery,
                skip,
                first: !!pageLimit ? pageLimit : 1000,
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }

    getAllTestCentresByType(type: string, pageLimit?: number, searchQuery?: string, skip = 0): Observable<FetchResult<any>> {
        return this.apollo.query({
            query: gql`
                query allTestCentresByType($type: TestCentreType, $first: Int, $skip: Int
                    ${!!searchQuery ? ', $searchQuery: String' : ''}
                ) {
                    testCentres(first: $first, skip: $skip,
                     where: {
                        AND: [
                            { type: $type }
                            ${!!searchQuery ? ', {name_contains: $searchQuery}' : ''}
                        ]
                    }) {
                        id
                        name
                        addressLine1
                        addressLine2
                        postCode
                        subscriptionId
                        city {
                            id
                            name
                            county {
                                id
                                name
                                description
                            }
                        }
                        instructorRate
                        type
                    }
                }`,
            variables: {
                type,
                searchQuery,
                skip,
                first: !!pageLimit ? pageLimit : 1000,
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }

    getAllTestCentresForDropdown(pageLimit?: number, skip = 0): Observable<FetchResult<any>> {
        return this.apollo.query({
            query: gql`
                query allTestCentres($first: Int, $skip: Int) {
                    testCentres(first: $first, skip: $skip) {
                        id
                        name
                        addressLine1
                        addressLine2
                        postCode
                        subscriptionId
                        city {
                            id
                        }
                    }
                }`,
            variables: {
                skip,
                first: !!pageLimit ? pageLimit : 1000000,
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all'
        });
    }

    createTestCentre(testCentre: TestCentre): Observable<FetchResult<TestCentre>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation createTestCentre($testCentre: TestCentreCreateInput!) {
                    createTestCentre(data: $testCentre) {
                        id
                        name
                        addressLine1
                        addressLine2
                        postCode
                        subscriptionId
                        city {
                            id
                            name
                            county {
                                id
                                name
                                description
                            }
                        }
                        instructorRate
                        type
                    }
                }`,
            variables: {
                testCentre: TestCentreMapperUtil.TestCentreToTestCentreCreateInput(testCentre),
            },
            errorPolicy: 'all'
        });
    }

    updateTestCentre(testCentre: TestCentre): Observable<FetchResult<TestCentre>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation updateTestCentre($testCentre: TestCentreUpdateInput!, $testCentreId: ID!) {
                    updateTestCentre(data: $testCentre, where: {id: $testCentreId}) {
                        id
                        name
                        addressLine1
                        addressLine2
                        postCode
                        subscriptionId
                        city {
                            id
                            name
                            county {
                                id
                                name
                                description
                            }
                        }
                        instructorRate
                        type
                    }
                }`,
            variables: {
                testCentre: TestCentreMapperUtil.TestCentreToTestCentreUpdateInput(testCentre),
                testCentreId: testCentre.id
            },
            errorPolicy: 'all'
        });
    }

    getTestCentre(id: string): Observable<ApolloQueryResult<any>> {
        return this.apollo.query({
            query: gql`
                query getTestCentreById($testCentreId: ID!) {
                    testCentre(where: {id: $testCentreId}) {
                        id
                        name
                        addressLine1
                        addressLine2
                        postCode
                        subscriptionId
                        city {
                            id
                            name
                            county {
                                id
                                name
                                description
                            }
                        }
                        instructorRate
                        type
                    }
                }`,
            variables: { testCentreId: id },
            errorPolicy: 'all'
        });
    }

    deleteTestCentre(id: string): Observable<FetchResult<TestCentre>> {
        return this.apollo.mutate({
            mutation: gql`
                mutation deleteTestCentreById($testCentreId: ID!) {
                    deleteTestCentre(where: {id: $testCentreId}) {
                        id
                        name
                    }
                }`,
            variables: { testCentreId: id },
            errorPolicy: 'all'
        });
    }
}

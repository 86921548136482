// Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

export const SideBarRoutes: RouteInfo[] = [
    {
        path: '/dashboard',
        title: 'DASHBOARD',
        type: 'link',
        icontype: 'dashboard'
    }, {
        path: '/applications',
        title: 'APPLICATIONS',
        type: 'link',
        icontype: 'format_list_bulleted'
    }, {
        path: '/test-bookings',
        title: 'TEST BOOKINGS',
        type: 'link',
        icontype: 'assignment'
    }, {
        path: '/stakeholder-management',
        title: 'STAKEHOLDER MANAGEMENT',
        type: 'link',
        icontype: 'short_text'
    }, {
        path: '/setup',
        title: 'SETUP',
        type: 'sub',
        icontype: 'polymer',
        collapse: 'SETUP',
        children: [
            {path: 'location', title: 'Locations', ab: 'LS'},
            {path: 'user', title: 'Users', ab: 'US'},
            {path: 'package', title: 'Packages & Pricing', ab: 'PS'},
            {path: 'skill', title: 'Skills', ab: 'SS'},
            {path: 'library', title: 'Library', ab: 'LB'},
            {path: 'test', title: 'Stripe', ab: 'ST'},
            // {path: 'course', title: 'Course', ab: 'CS'},
            // {path: 'stripe', title: 'Stripe', ab: 'ST'},
        ]
    }
];

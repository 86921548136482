import { Injectable } from '@angular/core';

@Injectable()
export class StateStorageService {
    constructor() {}

    setSessionToken(jwtToken): void {
        sessionStorage.setItem('jwtToken', jwtToken);
    }

    getSessionToken(): string {
        return sessionStorage.getItem('jwtToken');
    }

    removeSessionToken() {
        if (sessionStorage.getItem('jwtToken')) {
            sessionStorage.removeItem('jwtToken');
        }
    }

    getLocalPreviousState(): string {
        return localStorage.getItem('previousState');
    }

    resetLocalPreviousState(): void {
        localStorage.removeItem('previousState');
    }

    storeLocalUrl(url: string) {
        localStorage.setItem('previousUrl', url);
    }

    getLocalUrl(): string {
        return localStorage.getItem('previousUrl');
    }

}
